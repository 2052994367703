/** Function that takes another function and allows you to add static params.
 * e.g.
 * function foo(bar, baz)...
 * const bar = someObject;
 * const baz = someObjects;
 *
 * You can either:
 * bazes.map((bar, baz) => {})
 *
 * or you can curry beforehand since bar won't be changing.
 * const processBaz = curry(foo)(bar)
 * bazes.map(processBaz)
 *
 * @param func - A function you want to partially populate params for.
 * @returns {fn} - A function that can take as many or few params as needed in order to create new
 * variations of the function based on those cached params.
 */
function curry(func) {
  return function curried(...args) {
    if (args.length >= func.length) {
      return func.apply(this, args);
    }
    return function (...args2) {
      return curried.apply(this, args.concat(args2));
    };
  };
}
export default curry;
