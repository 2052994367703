// TODO: Delete this file?

import * as propTypes from 'lib/prop_types';

import { StudyTypes } from 'lib/generated_constants/projects';

export const locationPropType = propTypes.shape({
  address: propTypes.string,
  autocomplete: propTypes.string,
  city: propTypes.string,
  country: propTypes.string,
  googleMapsUrl: propTypes.string,
  latitude: propTypes.number,
  longitude: propTypes.number,
  parkingAvailable: propTypes.bool,
  parkingInfo: propTypes.string,
  regions: propTypes.array,
});

export const studyLengthPropType = propTypes.shape({
  days: propTypes.number,
  hours: propTypes.number,
  minutes: propTypes.number,
  weeks: propTypes.number,
});

const detailsObjDef = {
  accessCode: propTypes.string.isRequired,
  advancedScreening: propTypes.bool.isRequired,
  confirmationNotesHtml: propTypes.string,
  defaultModeratorId: propTypes.number.isRequired,
  defaultSessionLocation: propTypes.string,
  deviceRequirements: propTypes.object,
  incentive: propTypes.string.isRequired,
  interviewType: propTypes.string.isRequired,
  interviewTypeId: propTypes.number.isRequired,
  isPrivate: propTypes.bool.isRequired,
  location: locationPropType,
  name: propTypes.string.isRequired,
  numParticipants: propTypes.number.isRequired,
  organizationId: propTypes.number.isRequired,
  publicDescription: propTypes.string.isRequired,
  publicTitle: propTypes.string.isRequired,
  requestedOccupations: propTypes.string,
  sessionLength: propTypes.string.isRequired,
  studyLength: studyLengthPropType.isRequired,
  studyType: propTypes.string.isRequired,
  studyTypeId: propTypes.oneOf(Object.values(StudyTypes)).isRequired,
  teamId: propTypes.number.isRequired,
  timezone: propTypes.string.isRequired,
  wePay: propTypes.bool.isRequired,
};

const detailsPropType = propTypes.shape(detailsObjDef);

export const detailsWithObjTechReqPropType = propTypes.shape({
  ...detailsObjDef,
  deviceRequirements: propTypes.object.isRequired,
});

export default detailsPropType;
