import { QueryString } from '../http';

export function encodedParticipantSearchFilter({ filterRules, projectFilters }) {
  const searchFilter = {
    filter_rules: filterRules,
    project_filters: projectFilters,
  };

  const cleansedFilter = Object.keys(searchFilter).reduce((acc, key) => {
    const value = searchFilter[key];

    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});

  if (Object.values(cleansedFilter).length === 0) {
    // This will probably have to get tweaked once this method is more robust
    // but for now if there are no relevant pieces of data, return nothing
    return undefined;
  }

  return QueryString.base64Encode(cleansedFilter);
}
