import Papa from 'papaparse';

import isValidBoolean from 'lib/validators/is_valid_boolean';
import isValidEmail from 'lib/validators/is_valid_email';
import isValidISODate from 'lib/validators/is_valid_iso_date';

import { ATTRIBUTE_TYPES } from 'lib/generated_constants/attributes_constants';

// Keep this value < 0 so it doesn't conflict with a potential real value for a column.
// This is only to be used inside of this file. Return NULL if we can not find a valid email column.
const UNKNOWN_EMAIL_COLUMN = -1;

const PREVIEW_ROW_COUNT = 5;

const COLUMN_ERROR_DATA = 'data_error';
const COLUMN_ERROR_EMAIL = 'email_error';

export const COLUMN_ERRORS = {
  INVALID: Symbol('column invalid'),
  DUPLICATE_NAME: Symbol('duplicate name'),
  MISSING_NAME: Symbol('missing name'),
};

export function checkForErrors(rows, emailColumnIndex) {
  const errors = new Array(rows.length);

  // Only check we have right now is that an email is valid
  if (emailColumnIndex === null) {
    return errors;
  }

  rows.forEach((row, rowIdx) => {
    if (!isValidEmail(row[emailColumnIndex])) {
      errors[rowIdx] = 'Invalid email found.';
    }
  });

  return errors;
}

export function checkForRowErrors({ csvData, columnSelections, emailColumnIndex }) {
  const errors = new Array(columnSelections.length);

  csvData.forEach((row) => {
    columnSelections.forEach((columnSelection, index) => {
      if (!columnSelection || errors[index]) return;

      let errorType;

      const rowColumnValue = row[index]?.trim();

      // Don't worry about empty columns except for emails
      if (rowColumnValue === '' && emailColumnIndex !== index) { return; }

      const columnType = columnSelection.type;
      const columnName = columnSelection.name;

      if (columnType === ATTRIBUTE_TYPES.BOOLEAN && !isValidBoolean(rowColumnValue)) {
        errorType = COLUMN_ERROR_DATA;
      } else if (columnType === ATTRIBUTE_TYPES.DATE && !isValidISODate(rowColumnValue)) {
        errorType = COLUMN_ERROR_DATA;
      } else if (
        columnType === ATTRIBUTE_TYPES.DECIMAL && Number.isNaN(Number.parseFloat(rowColumnValue))
      ) {
        errorType = COLUMN_ERROR_DATA;
      } else if (emailColumnIndex === index && !isValidEmail(rowColumnValue)) {
        errorType = COLUMN_ERROR_EMAIL;
      }

      if (errorType) {
        errors[index] = {
          columnType,
          columnName,
          email: errorType === COLUMN_ERROR_EMAIL,
        };
      }
    });
  });

  return errors;
}

export function checkForColumnErrors(selectedCols, availableCols) {
  const errors = new Array(selectedCols.length);
  const nameCount = availableCols.filter(col => col.name).reduce((acc, col) => {
    acc[col.name] = (acc[col.name] || 0) + 1;
    return acc;
  }, []);

  selectedCols.forEach((column, index) => {
    if (!column) {
      return;
    }

    let error;
    if (!column.name) {
      error = COLUMN_ERRORS.MISSING_NAME;
    } else if (nameCount[column.name] > 1 || column.nameIsReserved) {
      error = COLUMN_ERRORS.DUPLICATE_NAME;
    } else if (!column.valid) {
      error = COLUMN_ERRORS.INVALID;
    }

    errors[index] = error;
  });

  return errors;
}

export function validateFoundEmailColumnIndex(rowEmailColumnIndexes) {
  // This method validates that all of the rows have ONE valid email index
  let confirmedColumnIndex = null;

  rowEmailColumnIndexes.forEach((column) => {
    if (column === null) {
      confirmedColumnIndex = UNKNOWN_EMAIL_COLUMN;
    } else if (confirmedColumnIndex === null) {
      confirmedColumnIndex = column;
    } else if (confirmedColumnIndex !== column) {
      confirmedColumnIndex = UNKNOWN_EMAIL_COLUMN;
    }
  });

  // If we did not find a good email column, null it out
  if (confirmedColumnIndex === UNKNOWN_EMAIL_COLUMN) {
    confirmedColumnIndex = null;
  }

  return confirmedColumnIndex;
}

export function findEmailColumnIndex(row) {
  // Inspects a row, and returns an index for email ONLY if one is found
  let emailColumn = null;

  row.forEach((cell, cellIdx) => {
    if (isValidEmail(cell)) {
      if (emailColumn === null) {
        emailColumn = cellIdx;
      } else {
        emailColumn = UNKNOWN_EMAIL_COLUMN;
      }
    }
  });

  if (emailColumn === UNKNOWN_EMAIL_COLUMN) {
    return null;
  }

  return emailColumn;
}

function isEmptyRow(row) {
  // I thought about checking truthyness here but a cell with 0 in it is not empty
  return row.filter(cell => cell !== undefined && cell !== null && cell !== '').length === 0;
}

export function processCSVData(data, fileHasHeader, previewRows = PREVIEW_ROW_COUNT) {
  const csvData = [];
  const emailColumns = [];

  let emailColumnIndex = null;
  let headerFound = fileHasHeader;
  let headerRow = null;

  if (data.length) {
    // Look for the email column by reading the headers
    // Only don't check if we have been told explicitly that this file does not have a header
    if (headerFound !== false) {
      emailColumnIndex = data[0].findIndex(header => header.toLowerCase() === 'email');
      if (emailColumnIndex === -1) {
        emailColumnIndex = null;
        // If we had a null reference in fileHasHeader, set it to false
        headerFound = headerFound || false;
      } else {
        headerFound = true;
      }
    }

    data.forEach((row, idx) => {
      if (headerFound) {
        // Ignore the header row if we found one
        if (idx === 0) {
          headerRow = row;
          return;
        }
      } else if (idx === previewRows) {
        // We always load N+1 rows, so we can detect the header above.
        // Therefore, we need to ignore the last row if we didn't find a header row.
        return;
      }

      // Ignore empty rows
      if (isEmptyRow(row)) {
        return;
      }

      csvData.push(row);
      emailColumns.push(findEmailColumnIndex(row));
    });

    // If we didn't find the email column in the header, let's try and be cute.
    // First try and use regexs to find a column full of emails
    if (emailColumnIndex === null) {
      emailColumnIndex = validateFoundEmailColumnIndex(emailColumns);

      // Then check if the length is 3 and assume it's the first column
      if (emailColumnIndex === null && data[0].length === 3) {
        emailColumnIndex = 0;
      }
    }
  }

  return {
    csvData,
    emailColumnIndex,
    headerRow,
  };
}

export default class ParticipantCSVProcessor {
  constructor({ columns = [], previewRows = PREVIEW_ROW_COUNT }) {
    this._availableColumns = columns;
    this._columnSelections = [];
    this._columnErrors = [];
    this._csvData = [];
    this._file = null;
    this._fileHasHeader = null;
    this._rowErrors = [];
    this._previewRows = Math.max(previewRows, PREVIEW_ROW_COUNT);
  }

  get emailColumnIndex() {
    // TODO: use normal -1 to represent absent index instead of mapping it to
    // null throughout this module.

    const emailColumnIndex = this._columnSelections
      .findIndex(column => Object(column).field === 'email');

    return emailColumnIndex === -1 ? null : emailColumnIndex;
  }

  get fileName() {
    return this._file.name;
  }

  _parseFile() {
    return new Promise((resolve) => {
      Papa.parse(this._file, {
        delimiter: ',',
        header: false,
        preview: this._previewRows + 1, // Include an extra row in case of header detection
        skipEmptyLines: true,
        complete: (results) => {
          const { csvData, emailColumnIndex, headerRow } =
            processCSVData(results.data, this._fileHasHeader, this._previewRows);

          this._csvData = csvData;
          this._fileHasHeader = !!headerRow;
          this._headerRow = headerRow;

          const csvDataLength = (this._csvData[0] || []).length;

          if (!this._columnSelections || this._columnSelections.length !== csvDataLength) {
            this._columnSelections = new Array(csvDataLength);
          }

          if (this._fileHasHeader && this._headerRow) {
            this._headerRow.forEach((field, index) => {
              const existingColumn =
                this._availableColumns.find(column => column.matchesHeader(field));

              if (existingColumn) this._columnSelections[index] = existingColumn;
            });
          }

          if (emailColumnIndex !== null) {
            this._columnSelections[emailColumnIndex] =
              this._availableColumns.find(column => column.matchesHeader('email'));
          }

          this._resolvePromise(resolve);
        },
      });
    });
  }

  // TODO: rebind ancient seal that keeps promise control flow inversions locked
  // within the unholy realm

  _resolvePromise(resolve) {
    if (this._availableColumns.length) {
      this._columnErrors = checkForColumnErrors(this._columnSelections, this._availableColumns);
    }

    this._rowErrors = checkForRowErrors({
      csvData: this._csvData.slice(0, PREVIEW_ROW_COUNT),
      columnSelections: this._columnSelections,
      emailColumnIndex: this.emailColumnIndex,
    }).filter(rowError => !!rowError);

    resolve({
      availableColumns: this._availableColumns,
      columnSelections: this._columnSelections,
      csvData: this._csvData,
      columnErrors: this._columnErrors,
      rowErrors: this._rowErrors,
      fileHasHeader: this._fileHasHeader,
      headerRow: this._headerRow,
    });
  }

  updateColumnSelection(column, index) {
    if (column !== null) {
      // If we have picked a new column, we should unset it from wherever else it is used
      const previousIndex = this._columnSelections.indexOf(column);

      if (previousIndex >= 0) {
        this._columnSelections[previousIndex] = null;
      }

      if (this._availableColumns.indexOf(column) < 0) {
        // Ensure any new columns are made available to other drop-downs
        this._availableColumns.push(column);
      }
    } else {
      const previousColumn = this._columnSelections[index];

      if (previousColumn && !previousColumn.persisted) {
        // If we are un-setting a non-persisted column, remove it from available options
        this._availableColumns = this._availableColumns.filter(
          availableColumn => availableColumn !== previousColumn,
        );
      }
    }

    // Finally update the selection
    this._columnSelections[index] = column;

    return new Promise((resolve) => { this._resolvePromise(resolve); });
  }

  updateColumn(column, opts = {}) {
    if (this._availableColumns.includes(column)) {
      column.update(opts);
      return new Promise((resolve) => { this._resolvePromise(resolve); });
    }

    return Promise.reject(new Error('Column not found'));
  }

  updateFile(file) {
    this._file = file;
    this._fileHasHeader = null;
    return this._parseFile();
  }

  updateFileHasHeader(value) {
    this._fileHasHeader = value;
    return this._parseFile();
  }

  isValidEmailColumn() {
    return this.emailColumnIndex !== null;
  }

  get validEmailColumn() {
    return this.isValidEmailColumn();
  }

  get validForUpload() {
    return this.isValidEmailColumn() && this._columnErrors.every(error => error === undefined);
  }
}
