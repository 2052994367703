import {
  faSortNumericDown,
  faSortNumericUp,
  faSortAlphaDown,
  faSortAlphaUp,
} from 'lib/font_awesome/solid';

export const SORT_DIRECTION_ASCENDING = 'asc';
export const SORT_DIRECTION_DESCENDING = 'desc';
export const SORT_DIRECTION_RESET = 'reset';

export const SORT_TYPE_DATE = 'date';
export const SORT_TYPE_NUMERIC = 'numeric';
export const SORT_TYPE_STRING = 'string';

export const SORT_TYPE_DATE_ASC_TEXT = 'Oldest';
export const SORT_TYPE_DATE_DESC_TEXT = 'Newest';

export const SORT_TYPE_NUMERIC_ASC_TEXT = '1 to 9';
export const SORT_TYPE_NUMERIC_DESC_TEXT = '9 to 1';

export const SORT_TYPE_STRING_ASC_TEXT = 'A to Z';
export const SORT_TYPE_STRING_DESC_TEXT = 'Z to A';

export const SHOW_DATA_ACTIONS_TEXT = 'Show data sorting actions';

export const SORT_CONTENT_MAP = {
  [SORT_TYPE_DATE]: {
    [SORT_DIRECTION_ASCENDING]: faSortNumericDown,
    [SORT_DIRECTION_DESCENDING]: faSortNumericUp,
    text: {
      [SORT_DIRECTION_ASCENDING]: SORT_TYPE_DATE_ASC_TEXT,
      [SORT_DIRECTION_DESCENDING]: SORT_TYPE_DATE_DESC_TEXT,
    },
  },
  [SORT_TYPE_NUMERIC]: {
    [SORT_DIRECTION_ASCENDING]: faSortNumericDown,
    [SORT_DIRECTION_DESCENDING]: faSortNumericUp,
    text: {
      [SORT_DIRECTION_ASCENDING]: SORT_TYPE_NUMERIC_ASC_TEXT,
      [SORT_DIRECTION_DESCENDING]: SORT_TYPE_NUMERIC_DESC_TEXT,
    },
  },
  [SORT_TYPE_STRING]: {
    [SORT_DIRECTION_ASCENDING]: faSortAlphaDown,
    [SORT_DIRECTION_DESCENDING]: faSortAlphaUp,
    text: {
      [SORT_DIRECTION_ASCENDING]: SORT_TYPE_STRING_ASC_TEXT,
      [SORT_DIRECTION_DESCENDING]: SORT_TYPE_STRING_DESC_TEXT,
    },
  },
};
