import {
  PROJECT_PARTICIPANT_ADMIN_PRE_CONFIRMED_STATUSES,
  PROJECT_PARTICIPANT_ADMIN_STATUSES,
  PROJECT_PARTICIPANT_CLIENT_FINALIZABLE_STATUSES,
  PROJECT_PARTICIPANT_CLIENT_FINALIZED_STATUSES,
  PROJECT_PARTICIPANT_CLIENT_HIDDEN_STATUSES,
  PROJECT_PARTICIPANT_CLIENT_SORTABLE_STATUSES,
  PROJECT_PARTICIPANT_CLIENT_SORTED_STATUSES,
  PROJECT_PARTICIPANT_FINALIZED_AND_CONFIRMED_STATUSES,
  PROJECT_PARTICIPANT_NON_FINALIZED_STATUSES,
  PROJECT_PARTICIPANT_ADVANCED_SCREENING_SCHEDULABLE_STATUSES,
} from 'lib/generated_constants/projects';

import { ProjectParticipantStatuses } from './enums';

const AdminPreConfirmedStatuses =
  new Set(ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_ADMIN_PRE_CONFIRMED_STATUSES));

const AdminStatuses =
  new Set(ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_ADMIN_STATUSES));

const AllSortedStatuses = ProjectParticipantStatuses.items();

const ClientFinalizedStatuses =
  new Set(ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_CLIENT_FINALIZED_STATUSES));

const ClientHiddenStatuses =
  new Set(ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_CLIENT_HIDDEN_STATUSES));

const ClientSortedStatuses =
  new Set(ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_CLIENT_SORTED_STATUSES));

const FinalizedAndConfirmedStatuses =
  new Set(ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_FINALIZED_AND_CONFIRMED_STATUSES));

const AdvancedScreeningSchedulableStatuses =
  new Set(
    ProjectParticipantStatuses.items(PROJECT_PARTICIPANT_ADVANCED_SCREENING_SCHEDULABLE_STATUSES),
  );

export {
  AdminPreConfirmedStatuses,
  AdminStatuses,
  AllSortedStatuses,
  ClientFinalizedStatuses,
  ClientHiddenStatuses,
  ClientSortedStatuses,
  AdvancedScreeningSchedulableStatuses,
};

export default class ProjectParticipantStateMachine {
  constructor(authorization) {
    this.statusMap = this.buildMap(authorization);
  }

  buildMap({
    sortParticipants = false,
    staffUpdate = false,
    finalizeParticipants = sortParticipants,
  }) {
    const statusMap = {};

    // Note that there is kind of an order of precedence here in regards to what takes priority
    // 1. Basic sorting
    // 2. Finalization
    // 3. Admin controls

    if (sortParticipants) {
      this.populateStatusTransition(
        statusMap,
        PROJECT_PARTICIPANT_CLIENT_SORTABLE_STATUSES,
        ClientSortedStatuses,
      );
    }

    if (finalizeParticipants) {
      this.populateStatusTransition(
        statusMap,
        PROJECT_PARTICIPANT_CLIENT_FINALIZABLE_STATUSES,
        ClientFinalizedStatuses,
      );
    }

    if (staffUpdate) {
      // Can transition confirmed and below status to any pre-confirmed status freely.
      // Confirmation must be done via booking actions completion must be done via schedule
      this.populateStatusTransition(
        statusMap,
        PROJECT_PARTICIPANT_NON_FINALIZED_STATUSES,
        AdminPreConfirmedStatuses,
      );

      // Can transition interview_completed to any finalized status. Can rollback to confirmed
      this.populateStatusTransition(
        statusMap,
        [ProjectParticipantStatuses.INTERVIEW_COMPLETED.key],
        FinalizedAndConfirmedStatuses,
      );

      // Can transition no_show to anything including rollback to confirmed
      this.populateStatusTransition(
        statusMap,
        [ProjectParticipantStatuses.NO_SHOW.key],
        AdminStatuses,
      );
    }

    return statusMap;
  }

  canTransition(
    fromStatus,
    toStatus,
    { canFinalizeParticipant = false, canCompleteParticipant = canFinalizeParticipant } = {},
  ) {
    const nextStatus = ProjectParticipantStatuses.item(toStatus);

    return this.nextStatuses(
      fromStatus,
      { canFinalizeParticipant, canCompleteParticipant },
    ).contain(nextStatus);
  }

  nextStatuses(
    currentStatus,
    { canFinalizeParticipant = false, canCompleteParticipant = canFinalizeParticipant } = {},
    sorted = false,
  ) {
    const canComplete = canCompleteParticipant || canFinalizeParticipant;
    const statusEnum = ProjectParticipantStatuses.item(currentStatus);

    const nextStatuses = this.statusMap[statusEnum] || [];
    const nextStatusArray = nextStatuses instanceof Set ? [...nextStatuses] : nextStatuses;

    // we must still confirm that THIS individual participant can be finalized
    const finalizeFiltered = nextStatusArray.filter((opt) => {
      // NOTE that finalize encompasses both complete and otherwise
      // there are special cases around complete/finalized... namely in that sometimes we allow
      // to mark a user completed but not did not show for things like unmoderated tasks
      if (opt === ProjectParticipantStatuses.INTERVIEW_COMPLETED) {
        return canComplete;
      }

      if (ClientFinalizedStatuses.has(opt)) {
        return canFinalizeParticipant;
      }

      // if not dealing w/ finalized statuses, assume we can do any transition
      return true;
    });

    if (sorted) {
      // this sorts with statuses in descending, so more final statuses are put at the end
      return finalizeFiltered.sort((a, b) => (b.sortOrder - a.sortOrder));
    }

    return finalizeFiltered;
  }

  populateStatusTransition(statusMap, statuses, nextStatuses) {
    statuses.forEach((status) => {
      // eslint-disable-next-line no-param-reassign
      statusMap[status] = nextStatuses;
    });
  }
}
