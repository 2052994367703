/* eslint-disable max-len */

export const eventPageNames = {
  ACCOUNT_INTEGRATIONS: 'Account Integrations',
  ACCOUNT_API_KEYS: 'Account API Keys',
  BUILDER: 'Project Builder',
  PROJECT_APPLY_SUCCESS: 'Project Apply Success',
  RECRUIT: 'Sourced Participants',
  WORKSPACE: 'Project Workspace',
  SIGNIN: 'Sign In',
  SIGNUP: 'Sign Up',
  // Team dashboard pages
  TEAM_BILLING: 'Team Billing',
  TEAM_BILLING_INFO: 'Team Billing Info',
  TEAM_BRANDING: 'Team Branding',
  TEAM_REPORTS: 'Team Reports',
  TEAM_DATA_CONSENT_SETTINGS: 'Team Data Consent Settings',
  TEAM_DOMAINS: 'Team Domain Settings',
  TEAM_EMAIL_SETS: 'Team Email Template Sets',
  TEAM_MEMBERS: 'Team Members',
  TEAM_NOTES: 'Team Notes',
  TEAM_SAML: 'Team SAML Configurations',

  PARTICIPANT_SCHEDULING: 'Participant Scheduling',

  // Unmoderated workflow pages
  OLD_LINK_TO_TASK_PAGE: 'Old Link to Task Page',
} as const;

// TODO Port the old world to use this constant when we bring it into the new one.
export const trackingEvents = {
  ACCOUNT_INTEGRATIONS_PAGE_VIEWED: 'Account -- Integrations Page Viewed',
  ACCOUNT_CHANGE_TEAM: 'Account -- Change Team',
  ACCOUNT_CHECKLIST_ITEM_CLICKED: 'Account -- Checklist item clicked',
  ACCOUNT_AVAILABILITY_UPDATED: 'Account -- Availability Updated',
  ACCOUNT_POST_CREATION_FORM_STEP_SKIPPED: 'Account -- Post creation form step skipped',
  ACCOUNT_POST_CREATION_FORM_STEP_SUBMITTED: 'Account -- Post creation form step submitted',
  ACCOUNT_POST_CREATION_FORM_COMPLETED: 'Account -- Post creation form completed',
  ACCOUNT_POST_CREATION_INTEGRATION_CONNECT_CLICKED: 'Account -- Post creation integration connect clicked',

  BUILD_PROJECT_LOOKBACK_LINK_CLICKED: 'Project Builder -- Lookback Link Clicked',
  BUILD_PROJECT_SCREENER_QUESTION_DUPLICATED: 'Project Builder -- Screener Question Duplicated',

  BILLING_CONTACT_SALES_CLICKED: 'Billing -- Contact Sales Clicked',
  BILLING_INFO_VIEWED_FROM_TEAM_DASHBOARD: 'Billing -- Team Billing Info Viewed From Team Dashboard',
  BILLING_SCHEDULE_DEMO_CLICKED: 'Billing -- Schedule Demo Clicked',
  BILLING_VIEWED_FROM_TEAM_DASHBOARD: 'Billing -- Team Billing Viewed From Team Dashboard',

  BILLING_UPGRADE_CONFIRMATION_LINK_CLICK: 'Billing -- Helpful link clicked from Plan Upgrade Modal',
  BILLING_PLANS_PAGE_CONTACT_SALES_CLICKED: 'Billing -- Plans Page Contact Sales Clicked',
  BILLING_PLANS_PAGE_GET_IN_TOUCH_CLICKED: 'Billing -- Plans Page Get In Touch Clicked',
  BILLING_PLANS_PAGE_UPDATE_CLICKED: 'Billing -- Plans Page Update Clicked',
  BILLING_PLANS_PAGE_UPGRADE_CLICKED: 'Billing -- Plans Page Upgrade Clicked',
  BILLING_PLANS_PAGE_VIEW_PLAN_DETAILS_CLICKED: 'Billing --- Plans Page View Plan Details Clicked',

  BRANDING_SAVE_LOGO_BUTTON_CLICKED: 'Branding -- Save Logo Button Clicked',
  BRANDING_UPLOAD_LOGO_BUTTON_CLICKED: 'Branding -- Upload or Replace Logo Button Clicked',
  BRANDING_PAGE_VIEWED: 'Branding -- Branding Page Viewed',

  BRANDING_CREATE_NEW_EMAIL_THEME_CLICKED: 'Branding -- Create New Email Theme Clicked',
  BRANDING_DUPLICATE_EMAIL_THEME_CLICKED: 'Branding -- Duplicate Email Theme Clicked',
  BRANDING_EDIT_EMAIL_THEME_CLICKED: 'Branding -- Edit Email Theme Clicked',
  BRANDING_EMAIL_THEME_DELETED: 'Branding -- Email Theme Deleted',
  BRANDING_EMAIL_THEME_CHANGES_DISCARDED: 'Branding -- Email Theme Changes Discarded',
  BRANDING_REMOVE_DEFAULT_SUCCESS: 'Branding -- Remove Default Success',
  BRANDING_SET_DEFAULT_EMAIL_THEME_SUCCESS: 'Branding -- Set Default Email Theme Success',

  COMMENTS_CLOSED: 'Comments Drawer Closed',
  COMMENTS_OPENED: 'Comments Drawer Opened',
  COMMENTS_INVITE_FROM_MENTION: 'Comments -- Invite From Mention',

  // Calendar
  CONNECT_CALENDAR_MODAL_OPENED: 'Connect Your Calendar -- Modal Opened',
  CONNECT_CALENDAR_SYNCED: 'Connect Your Calendar -- Calendar Synced',
  CONNECT_WITH_ZOOM_CLICKED: 'Integrations -- Connect with Zoom Clicked',

  // Integrations
  API_KEY_FORM_SUBMITTED: 'Integrations -- API Key Form Submitted',
  CONNECT_WITH_INTEGRATION_CLICKED: 'Integrations -- Connect with Integration Clicked',
  DISCONNECT_WITH_INTEGRATION_CLICKED: 'Integrations -- Disconnect with Integration Clicked',
  RECONNECT_WITH_INTEGRATION_CLICKED: 'Integrations -- Reconnect with Integration Clicked',
  DISCONNECT_WITH_GOOGLE_CLICKED: 'Integrations -- Disconnect with Google Clicked',
  DISCONNECT_WITH_OFFICE365_CLICKED: 'Integrations -- Disconnect with Office365 Clicked',
  DISCONNECT_WITH_MICROSOFT_CLICKED: 'Integrations -- Disconnect with Microsoft Clicked',
  HELP_LINK_INTEGRATION_CLICKED: 'Integrations -- Help link for Integration Clicked',
  REFERRAL_LINK_INTEGRATION_CLICKED: 'Integrations -- Referral link for Integration Clicked',
  SESSION_ACTIVITY_LINK_CLICKED: 'Integrations -- Session Activity Link Clicked',
  SUGGESTED_INTEGRATION_ALERT_SHOWN: 'Integrations -- Suggested Integration Alert based on Custom URL Shown',
  SUGGESTED_INTEGRATION_ALERT_CONNECT_CLICKED: 'Integrations -- Suggested Integration Alert -- Connect with Integration Clicked',
  QUALTRICS_NON_BRAND_ADMIN_TOKEN_MODAL_CONTENTS_SHOWN: 'Qualtrics -- non-Brand Admin Token Modal contents Shown',
  QUALTRICS_NON_BRAND_ADMIN_TOKEN_MODAL_COPY_BUTTON_CLICKED: 'Qualtrics -- non-Brand Admin Token Modal - Copy Message Button Clicked',

  // Integrations -- deprecated
  DISCONNECT_WITH_ZOOM_CLICKED: 'Integrations -- Disconnect with Zoom Clicked',

  DATA_CONSENT_SETTINGS_VIEWED_FROM_TEAM_DASHBOARD: 'Data Consent -- Viewed from Team Documents Page',
  DATA_CONSENT_SETTINGS_DISCARD_CHANGES_CLICKED: 'Data Consent -- Discard changes Clicked',
  DATA_CONSENT_SETTINGS_SAVE_CHANGES_CLICKED: 'Data Consent -- Save changes Clicked',

  DOMAIN_SETTINGS_ADD_DOMAIN_CLICKED: 'Domain Settings -- Add Domain Clicked',
  DOMAIN_SETTINGS_DNS_HOST_NAME_COPIED: 'Domain Settings -- Host Name Copied',
  DOMAIN_SETTINGS_DNS_REQUIRED_VALUE_COPIED: 'Domain Settings -- Required Value Copied',
  DOMAIN_SETTINGS_VIEWED_FROM_TEAM_DASHBOARD: 'Domain Settings -- Viewed from Team Domain Settings Page',
  DOMAIN_SETTINGS_SAVED: 'Domain Settings -- Domain Settings Saved',
  DOMAIN_SETTINGS_CREATE_PROFILE_CLICKED: 'Domain Settings -- Create Sender Profile Clicked',
  DOMAIN_SETTINGS_CREATE_PROFILE_SAVED: 'Domain Settings -- Sender Profile Saved',
  DOMAIN_SETTINGS_PROFILE_PREFERENCE_DEFAULT: 'Domain Settings -- Default Profile Preference Selected',
  DOMAIN_SETTINGS_PROFILE_PREFERENCE_CUSTOM: 'Domain Settings -- Custom Profile Preference Selected',

  PARTICIPANT_PROFILE_PROJECT_VIEW_PROJECT: 'Participant Profile -- View project clicked',
  PARTICIPANT_PROFILE_PROJECT_PROJECT_EXPANDED: 'Participant Profile -- Project expanded',
  PARTICIPANT_PROFILE_SCREENER_RESPONSE_DELETE_CLICKED: 'Participant Profile -- Screener Response Delete Clicked',

  PROJECT_CUSTOM_EMAIL_SET_CREATE_CLICKED: 'Create Custom Email Set Clicked',
  PROJECT_CUSTOM_EMAIL_SET_DEFAULT_TEMPLATE_SET_SUCCESS: 'Email Template Set as team default',
  PROJECT_CUSTOM_EMAIL_SET_EDIT_CLICKED: 'Edit Custom Email Set Clicked',
  PROJECT_CUSTOM_EMAIL_SET_VIEW_CLICKED: 'View Custom Email Set Clicked',
  PROJECT_CUSTOM_EMAIL_SET_PROJECT_CUSTOM_EMAIL_SAVED: 'Email Template Set Custom Email Saved',
  PROJECT_CUSTOM_EMAIL_SET_PROJECT_CUSTOM_EMAIL_EDIT_CLICKED: 'Edit Email Template Set Custom Email Clicked',
  PROJECT_CUSTOM_EMAIL_SETS_TABLE_FILTER_SELECTED: 'Email Template Sets Table Filter Selected',
  PROJECT_CUSTOM_EMAIL_SETS_TABLE_FILTER_DESELECTED: 'Email Template Sets Table Filter Deselected',

  /* Hub Events */
  HUB_BULK_ACTIONS_ADD_LABEL_TO_PARTICIPANT_CLICKED: 'Hub -- Add Labels From Bulk Actions Dropdown Clicked',
  HUB_BULK_ACTIONS_COMPOSE_EMAIL_CLICKED: 'Hub -- Compose Email From Bulk Actions Dropdown Clicked',
  HUB_BULK_ACTIONS_DELETE_PARTICIPANT_CLICKED: 'Hub -- Delete Selected Participants From Bulk Actions Dropdown Clicked',
  HUB_BULK_ACTIONS_UPDATE_TEAM_ACCESS_CLICKED: 'Hub -- Update Team Access From Bulk Actions Dropdown Clicked',

  HUB_ADD_LABEL_TO_PARTICIPANT_FROM_BOTTOM_NAV_CLICKED: 'Hub -- Add Labels From Bottom Nav Clicked',
  HUB_ADD_LABEL_TO_PARTICIPANT_SAVED: 'Hub -- Participant Label Saved',
  HUB_ADD_PARTICIPANTS_MODAL_CTA_CLICKED: 'Hub -- Add Participants Modal CTA Clicked',
  HUB_ADD_PARTICIPANTS_EDIT_SAMPLE_COUNT_CLICKED: 'Hub -- Add Participants Edit Sample Count Clicked',
  HUB_ADD_TO_PROJECT_FROM_BOTTOM_NAV_CLICKED: 'Hub -- Add To Project From Bottom Nav Clicked',
  HUB_ADD_SAMPLE_TO_PROJECT_FROM_BOTTOM_NAV_CLICKED: 'Hub -- Add Sample To Project from Bottom Nav Clicked',
  HUB_CREATE_PARTICIPANT_MODAL_SUBMIT: 'Hub Create Participant Modal -- Submit',
  HUB_DELETE_PARTICIPANT_FROM_BOTTOM_NAV_CLICKED: 'Hub -- Delete Selected Participants From Bottom Nav Clicked',
  HUB_COLUMN_CONFIGURATION_DND_COMPLETED: 'Hub -- Column Configuration DND Completed',
  HUB_COLUMN_CONFIGURATION_HIDE_ALL_CLICKED: 'Hub -- Column Configuration Hide All Clicked',
  HUB_COLUMN_CONFIGURATION_SHOW_ALL_CLICKED: 'Hub -- Column Configuration Show All Clicked',
  HUB_COLUMN_CONFIGURATION_TOGGLE_HIDE_CLICKED: 'Hub -- Column Configuration Toggle Hide Clicked',
  HUB_EDIT_FIELDS_CLICKED: 'Hub -- Edit Fields Clicked',
  HUB_EDIT_FIELDS_DRAWER_CLOSED: 'Hub -- Edit Fields Drawer Closed',
  HUB_EDIT_FIELDS_EDIT_CLICKED: 'Hub -- Edit Fields Edit Clicked',
  HUB_EDIT_FIELDS_NAME_CLICKED: 'Hub -- Edit Fields Name Clicked',
  HUB_EDIT_FIELDS_EDIT_CANCEL_CLICKED: 'Hub -- Edit Fields Edit Cancel Clicked',
  HUB_EDIT_FIELDS_EDIT_SAVE_CLICKED: 'Hub -- Edit Fields Edit Save Clicked',
  HUB_EDIT_FIELDS_DELETE_CLICKED: 'Hub -- Edit Fields Delete Clicked',
  HUB_EDIT_LABELS_CLICKED: 'Hub -- Edit Labels Clicked',
  HUB_EDIT_INVITE_RULES_CLICKED: 'Hub -- Edit Invite Rules Clicked',
  HUB_EDIT_OPT_IN_CLICKED: 'Hub -- Manage Opt In Clicked',
  HUB_NEW_OPT_IN_CLICKED: 'Hub -- New Opt In Clicked',
  HUB_PREVIEW_OPT_IN_CLICKED: 'Hub -- Preview Opt In Clicked',
  HUB_EMPTY_POPULATION_EDIT_OPT_IN_CLICKED: 'Empty Population -- Edit Opt In Clicked',
  HUB_EMPTY_POPULATION_UPLOAD_CSV_CLICKED: 'Empty Population -- Upload CSV Clicked',
  HUB_EMPTY_POPULATION_NEW_PROJECT_CLICKED: 'Empty Population -- New Project Clicked',
  HUB_EMPTY_POPULATION_RECRUIT_PARTICIPANTS_CLICKED: 'Empty Population -- Recruit Participants Clicked',
  HUB_EXPORT_CLICKED: 'Hub -- Export Button Clicked',
  HUB_HEADER_BUILD_BUTTON_CLICKED: 'Hub Header -- Build Clicked',
  HUB_HEADER_CREATE_PARTICIPANT_CLICKED: 'Hub Header -- Create Participant Clicked',
  HUB_HEADER_UPLOAD_CSV_CLICKED: 'Hub Header -- Upload CSV Clicked',
  HUB_IMPORT_PARTICIPANTS_MODAL_PREVIEW_UPLOAD_CLICKED: 'Hub Import Participants Modal -- Preview Upload Button Clicked',
  HUB_IMPORT_PARTICIPANTS_MODAL_PREVIEW_UPLOAD_BACK_CLICKED: 'Hub Import Participants Modal -- Back From Preview Upload Button Clicked',
  HUB_IMPORT_PARTICIPANTS_MODAL_UPLOAD_CLICKED: 'Hub Import Participants Modal -- Upload Button Clicked',
  HUB_INVITE_PARTICIPANTS_MODAL_PROJECT_INVITE_PROJECT_SELECTED: 'Hub Invite Participants Modal -- Project Invite Project Selected',
  HUB_INVITE_PARTICIPANTS_MODAL_PROJECT_INVITE_WARNING_DISPLAYED: 'Hub Invite Participants Modal -- Project Invite Warning Displayed',
  HUB_INVITE_SAMPLE_TO_PROJECT_FROM_BOTTOM_NAV_CLICKED: 'Hub -- Invite Sample To Project from Bottom Nav Clicked',
  HUB_SEND_INVITE_CLICKED: 'Hub -- Send Invite Clicked',
  HUB_SEND_INVITE_EDIT_CLICKED: 'Hub -- Send Invite Edit Participant Count Clicked',
  HUB_INVITE_TO_PROJECT_FROM_BOTTOM_NAV_CLICKED: 'Hub -- Invite To Project From Bottom Nav Clicked',
  HUB_MANAGE_INVITE_RULES_CLICKED: 'Hub -- Manage Hub Invite Rules Clicked',
  HUB_PARTICIPANT_TABLE_COLUMN_ACTIONS_ICON_CLICKED: 'Hub -- Participant Table Column Actions Icon Clicked',
  HUB_PARTICIPANT_TABLE_HIDE_COLUMN_CLICKED: 'Hub -- Participant Table Hide Column Clicked',
  HUB_PARTICIPANT_TABLE_DND_COMPLETED: 'Hub -- Participant Table Column DnD Completed',
  HUB_PARTICIPANT_TABLE_SHOW_ALL_LABELS_CLICKED: 'Hub -- Participant Table Show All Labels Clicked',
  HUB_PARTICIPANT_TABLE_SORT_CLICKED: 'Hub -- Participant Table Sort Clicked',
  HUB_PARTICIPANT_TABLE_TEAM_PICKER_SET: 'Hub -- Participant Table Team Picker Set',
  HUB_PARTICIPANT_TABLE_VIEW_PROFILE_CLICKED: 'Hub -- Participant Table View Profile Clicked',
  HUB_PUBLISH_INVITE_RULES_CLICKED: 'Hub -- Publish Hub Invite Rules Clicked',
  HUB_SELECT_ALL_PARTICIPANTS_CLICKED: 'Hub -- Select All Participants Clicked',
  HUB_UPDATE_TEAM_ACCESS_CLICKED: 'Hub -- Update team access clicked',
  HUB_UPDATE_TEAM_ACCESS_SAVED: 'Hub -- Update team access saved',
  HUB_UPLOAD_CSV_WITH_TEAM_SELECTOR_SELECT_FILE_CLICKED: 'Hub -- Upload CSV With Team Selector Select File Clicked',
  HUB_UPLOAD_CSV_WITH_TEAM_SELECTOR_UPLOAD_CLICKED: 'Hub -- Upload CSV With Team Selector Upload Clicked',
  HUB_UPGRADE_PROMPT_SHOWN: 'Hub Upgrade Prompt -- Shown',
  HUB_UPGRADE_PROMPT_CLICKED: 'Hub Upgrade Prompt -- Clicked',

  /* Hub CRM Overhaul Events */
  HUB_CRM_FILTERS_APPLIED_NEW: 'Hub -- Filters applied (new)',
  HUB_CRM_FILTERS_APPLIED_DIRTY: 'Hub -- Filters applied (dirty)',
  HUB_CRM_FILTERS_APPLIED_SAVED: 'Hub -- Filters applied (saved)',
  HUB_CRM_FILTERS_CLEARED: 'Hub -- Filter cleared',
  HUB_CRM_FILTERS_RESET: 'Hub -- Filter reset',
  HUB_CRM_LABEL_ADDED: 'Hub -- Label added to filter',
  HUB_CRM_LABEL_REMOVED: 'Hub -- Label removed from filter',
  HUB_CRM_SAVE_NEW_FAILED: 'Hub -- Save new filter failed',
  HUB_CRM_SAVE_NEW_FILTER: 'Hub -- Saved new filter',
  HUB_CRM_SAVE_EXISTING_FAILED: 'Hub -- Saving existing filter failed',
  HUB_CRM_SAVE_EXISTING_FILTER: 'Hub -- Saved existing filter',
  HUB_CRM_SAVED_FILTER_SELECTED: 'Hub -- Saved filter selected',

  /* Hub Participants (? unfortunate these were not consistent) */
  HUB_EDIT_PARTICIPANT: 'Hub Edit Participant -- Page Loaded',
  HUB_EDIT_PARTICIPANT_SAVE_CLICKED: 'Hub Edit Participant -- Save Clicked',
  HUB_PROFILE_EDIT_PARTICIPANT_LINK_CLICKED: 'Hub Profile -- Edit Participant Link Clicked',
  HUB_PROFILE_LOADED: 'Hub Profile -- Page Loaded',
  HUB_PROFILE_INVITE_CLICKED: 'Hub Profile -- Invite Clicked',
  HUB_PROFILE_PROJECT_HISTORY_TAB_CLICKED: 'Hub Profile -- Project History Tab Clicked',
  HUB_PROFILE_OVERVIEW_TAB_CLICKED: 'Hub Profile -- Overview Tab Clicked',
  HUB_PROFILE_SEND_INVITE_CLICKED: 'Hub Profile -- Send Invite Clicked',
  HUB_PROFILE_SEND_INVITE_EDIT_CLICKED: 'Hub Profile -- Send Invite Edit Participant Count Clicked',

  /* Hub Segments */
  HUB_NEW_SEGMENT_ADD_CLICKED: 'Hub New Segment -- Add Clicked',
  HUB_NEW_SEGMENT_CANCELLED: 'Hub New Segment -- Cancelled',
  HUB_NEW_SEGMENT_CREATE_CLICKED: 'Hub New Segment -- Create Clicked',
  HUB_NEW_SEGMENT_SAVE_CLICKED: 'Hub New Segment -- Save Clicked',
  HUB_SEGMENT_SIDEBAR_DELETE_CLICKED: 'Hub Segment Sidebar -- Delete Clicked',
  HUB_SEGMENT_SIDEBAR_DELETE_SAVE_CLICKED: 'Hub Segment Sidebar -- Delete Save Clicked',
  HUB_SEGMENT_SIDEBAR_DELETE_SUCCESS: 'Hub Segment Sidebar -- Delete Success',
  HUB_SEGMENT_SIDEBAR_DUPLICATE_CLICKED: 'Hub Segment Sidebar -- Duplicate Clicked',
  HUB_SEGMENT_SIDEBAR_DUPLICATE_SAVE_CLICKED: 'Hub Segment Sidebar -- Duplicate Save Clicked',
  HUB_SEGMENT_SIDEBAR_DUPLICATE_SUCCESS: 'Hub Segment Sidebar -- Duplicate Success',
  HUB_SEGMENT_SIDEBAR_EDIT_CANCEL_CLICKED: 'Hub Segment Sidebar -- Edit Cancel Clicked',
  HUB_SEGMENT_SIDEBAR_EDIT_CLICKED: 'Hub Segment Sidebar -- Edit Clicked',
  HUB_SEGMENT_SIDEBAR_EDIT_SUCCESS: 'Hub Segment Sidebar -- Edit Success',
  HUB_SEGMENT_SIDEBAR_EDIT_UPDATE_CLICKED: 'Hub Segment Sidebar -- Edit Update Clicked',
  HUB_SEGMENT_SIDEBAR_RENAME_CLICKED: 'Hub Segment Sidebar -- Rename Clicked',
  HUB_SEGMENT_SIDEBAR_RENAME_SAVE_CLICKED: 'Hub Segment Sidebar -- Rename Save Clicked',
  HUB_SEGMENT_SIDEBAR_RENAME_SUCCESS: 'Hub Segment Sidebar -- Rename Success',
  HUB_SEGMENT_SIDEBAR_SEGMENT_SELECTED: 'Hub Segment Sidebar -- Segment Selected',
  HUB_SEGMENT_SIDEBAR_UPDATE_PERMISSIONS_CLICKED: 'Hub Segment Sidebar -- Update Permissions Clicked',
  HUB_SEGMENT_SIDEBAR_UPDATE_PERMISSIONS_SAVE_CLICKED: 'Hub Segment Sidebar -- Update Permissions Save Clicked',

  HUB_SEGMENT_SAVE_AS_NEW_BUTTON_ERROR: 'Hub Segment Save As New Button -- Save Error',
  HUB_SEGMENT_SAVE_AS_NEW_BUTTON_SUCCESS: 'Hub Segment Save As New Button -- Save Success',
  HUB_SEGMENT_SAVE_BUTTON_SUCCESS: 'Hub Segment Save Button -- Update Success',

  /* Hub Segment Builder */
  HUB_SEGMENT_BUILDER_APPLY_FILTERS_CLICKED: 'Hub Segment Builder -- Apply Filters Clicked',
  HUB_SEGMENT_BUILDER_CLEAR_FILTERS_CLICKED: 'Hub Segment Builder -- Clear Filters Clicked',
  HUB_SEGMENT_BUILDER_OPENED: 'Hub Segment Builder -- Opened',
  HUB_SEGMENT_BUILDER_FILTER_SELECTED: 'Hub Segment Builder -- Filter Selected',
  HUB_SEGMENT_BUILDER_ADD_FILTER_CLICKED: 'Hub Segment Builder -- Add Filter Clicked',
  HUB_SEGMENT_BUILDER_DELETE_FILTER_CLICKED: 'Hub Segment Builder -- Delete Filter Clicked',
  HUB_SEGMENT_BUILDER_OPERAND_SELECTED: 'Hub Segment Builder -- Operand Selected',

  INCENTIVE_REDEMPTION_PAGE_LOADED: 'Incentives -- Redemption Page loaded',
  INCENTIVE_REDEEMED_PAGE_LOADED: 'Incentives -- View Redeemed Incentive Page loaded',

  INVITE_PARTICIPANTS_MODAL_SHOWN: 'Invite Participants to Project Modal Shown',
  INVITE_PARTICIPANTS_INVITE_SENT: 'Invite Participants to Project Invite Sent',
  INVITE_PARTICIPANTS_CREATE_NEW: 'Invite Participants to Project -- Create New Project',
  INVITE_PARTICIPANTS_DUPLICATE: 'Invite Participants to Project -- Duplicate Project',

  NEW_PARTICIPANT_ONBOARDING_NAVIGATION_BUTTON_CLICKED: 'New Participant Onboarding -- Navigation Button Clicked',

  NEW_PROJECT_BUILDER_LOADED: 'New Project Builder -- Page Loaded',
  NEW_PROJECT_BUILDER_OPTION_SELECTED: 'New Project Builder -- Option Selected',
  NEW_PROJECT_BUILDER_CONTINUE_BUTTON_CLICKED: 'New Project Builder -- Continue Button Clicked',
  NEW_PROJECT_BUILDER_TEMPLATE_CREATED: 'New Project Builder -- Template Created',

  OPT_IN_ATTRIBUTE_CREATED: 'Opt In -- Attribute Created',
  OPT_IN_EDIT_FORM_LOADED: 'Opt In -- Edit Form Page Loaded',
  OPT_IN_FORM_LINK_COPIED: 'Opt In -- Form Link Copied',
  OPT_IN_FORM_SAVED: 'Opt In -- Form Saved',
  OPT_IN_FORM_DELETED: 'Opt In -- Form Deleted',
  OPT_IN_FORM_DUPLICATED: 'Opt In -- Form Duplicated',
  OPT_IN_PREVIEW_CLICKED: 'Opt In -- Preview Clicked',
  OPT_IN_VISIBILITY_TOGGLED: 'Opt In -- Visibility Toggled',
  PARTICIPANT_INCENTIVE_REWARD_CODE_COPIED: 'Participant Incentives -- Reward code copied',
  PARTICIPANT_PROPOSE_TIMES_BUTTON_CLICKED: 'Participant Propose Times -- Propose Times Button Clicked',
  PARTICIPANT_VIEW_STUDY_DETAILS_CLICKED: 'Participant Project Dashboard -- View Study Details Clicked',
  PROJECT_REVIEW_SUBSCRIPTION_UPGRADE_SHOWN: 'Project Launch Review -- Subscription Upgrade Bar Shown',
  RESEARCHER_ONBOARDING_TEAM_INVITE_VIEW: 'Researcher Onboarding -- Team Invite View',
  RESEARCHER_ONBOARDING_VERIFY_EMAIL_VIEW: 'Researcher Onboarding -- Verify Email View',
  SAML_CONFIGURATIONS_VIEWED_FROM_TEAM_DASHBOARD: 'Team -- SAML Configurations Viewed From Team Page',
  SAML_CONFIGURATION_COPIED_ACS_URL: 'TEAM -- SAML Configuration copied ACS URL',
  SAML_CONFIGURATION_COPIED_ENTITY_ID: 'TEAM -- SAML Configuration copied Entity ID',
  CONNECT_GOOGLE_CALENDAR_MODAL_CONNECT_CLICKED: 'Researcher -- Connect Google Calendar Modal Connect Clicked',
  CONNECT_GOOGLE_CALENDAR_MODAL_OPENED: 'Researcher -- Connect Google Calendar Modal Opened',
  CONNECT_GOOGLE_CALENDAR_MODAL_CLOSED: 'Researcher -- Connect Google Calendar Modal Closed',
  CONNECT_WITH_GOOGLE_CLICKED: 'Researcher -- Connect with Google Clicked',
  SIGN_IN_WITH_GOOGLE_CLICKED: 'Researcher -- Sign In With Google Clicked',
  SIGN_UP_WITH_GOOGLE_CLICKED: 'Researcher -- Sign Up With Google Clicked',
  CONNECT_WITH_OFFICE365_CLICKED: 'Researcher -- Connect with Office365 Clicked',
  CONNECT_WITH_MICROSOFT_CLICKED: 'Researcher -- Connect with Microsoft Clicked',

  // Oauth
  OAUTH_BUTTON_CLICKED: 'Oauth -- Form Submit Button Clicked',

  PAGE_TEMPLATES_VIEWED: 'Page Templates -- Page Viewed',
  PAGE_TEMPLATE_EDIT_VIEWED: 'Page Template Edit -- Page Viewed',
  PAGE_TEMPLATE_EDIT_SAVE_PAGE_CLICK: 'Page Template Edit -- Save Page Click',
  PAGE_TEMPLATE_VIEWED: 'Page Template -- Page Viewed',

  PAGE_THEME_SETTINGS_DISCARD_CHANGES_CLICKED: 'Page Theme -- Discard changes Clicked',
  PAGE_THEME_SETTINGS_SAVE_CHANGES_CLICKED: 'Page Theme -- Save changes Clicked',

  // Participant Profile
  PARTICIPANT_EDIT_PROFILE_TAB_LINK_CLICKED: 'Participant Edit Profile -- Tab Link Clicked',
  PARTICIPANT_EDIT_PROFILE_SAVE_BUTTON_CLICKED: 'Participant Edit Profile -- Save Button Clicked',

  // Participant General Referrals
  PARTICIPANT_GENERAL_REFERRAL_PAGE_VIEWED: 'Participant General Referrals -- Page Viewed',
  PARTICIPANT_GENERAL_REFERRAL_LINK_COPIED: 'Participant General Referrals -- Copy Link Clicked',
  PARTICIPANT_GENERAL_REFERRAL_EMAIL_SHARE_CLICKED: 'Participant General Referrals -- Email Share Clicked',
  PARTICIPANT_GENERAL_REFERRAL_WEB_SHARE_CLICKED: 'Participant General Referrals -- Web Share Clicked',

  // Participant Project Referrals
  PARTICIPANT_COPY_REFERRAL_LINK_CLICKED: 'Participant -- Copy Referral Link Clicked',
  PARTICIPANT_EMAIL_REFERRAL_LINK_CLICKED: 'Participant -- Email Referral Clicked',
  PARTICIPANT_REFERRAL_CARD_VIEWED: 'Participant -- Referral Card Viewed',

  // Participant OAuth
  PARTICIPANT_OAUTH_CONNECT_CLICKED: 'Participant Connect OAuth Clicked',
  PARTICIPANT_OAUTH_SIGNUP_CLICKED: 'Participant Signup OAuth Clicked',
  PARTICIPANT_OAUTH_SIGNIN_CLICKED: 'Participant Signin OAuth Clicked',

  // Participant Recruit Application Flow
  PARTICIPANT_RECRUIT_APPLICATION_FLOW_SUCCESS_PAGE_ACCORDION_OPENED: 'Participant Recruit Application Flow - Success Page Accordion Opened',
  PARTICIPANT_RECRUIT_APPLICATION_FLOW_SUCCESS_PAGE_BROWSE_STUDIES_CLICKED: 'Participant Recruit Application Flow - Success Page Browse Studies Clicked',
  PARTICIPANT_RECRUIT_APPLICATION_FLOW_SUCCESS_PAGE_COPY_PROJECT_LINK_CLICKED: 'Participant Recruit Application Flow - Success Page Copy Project Link Clicked',

  // Participant Scheduling
  PARTICIPANT_SELECTED_RANGE: 'Participant -- Time Slot Selected',
  GOOGLE_CALENDAR_EVENT_BUTTON_CLICKED: 'Google Calendar Event Button Clicked',
  ICAL_DOWNLOAD_BUTTON_CLICKED: 'iCal Download Button Clicked',
  TASK_CTA_BUTTON_CLICKED: 'Task CTA Button Clicked',

  // Participant Incentive Editing
  PARTICIPANT_INCENTIVE_CREATE_BUTTON_CLICKED: 'Participant Incentive - Create Button Clicked',
  PARTICIPANT_INCENTIVE_EDIT_AMOUNT_BUTTON_CLICKED: 'Participant Incentive - Edit Button Clicked',
  PARTICIPANT_INCENTIVE_CREATED: 'Participant Incentive - Incentive Created',
  PARTICIPANT_INCENTIVE_AMOUNT_UPDATED: 'Participant Incentive - Amount Updated',
  PARTICIPANT_INCENTIVE_UPDATE_ERROR: 'Participant Incentive - Error Updating Amount',
  PARTICIPANT_INCENTIVE_UPDATE_AMOUNT_TOO_LOW: 'Participant Incentive - Update to amount below minimum prevented',
  PARTICIPANT_INCENTIVE_OPEN_UPDATE_MODAL_CLICKED: 'Participant Tracking - Open Incentive Update Modal Clicked',

  // Payment Methods
  PAYMENT_METHODS_TOGGLED: 'Payment Methods -- Toggled',

  // Prepaid Balances
  PREPAID_BALANCE_ADD_ACCOUNT_SAVE_CLICKED: 'Prepaid Balance -- Account Add Save Clicked',
  PREPAID_BALANCE_ADD_TRANSACTION_SAVE_CLICKED: 'Prepaid Balance -- Transaction Add Save Clicked',
  PREPAID_BALANCE_CREATE_SAVE_CLICKED: 'Prepaid Balance -- Create Save Clicked',
  PREPAID_BALANCE_EDIT_ACCOUNT_SAVE_CLICKED: 'Prepaid Balance -- Account Edit Save Clicked',
  PREPAID_BALANCE_EDIT_TRANSACTION_SAVE_CLICKED: 'Prepaid Balance -- Transaction Edit Save Clicked',
  PREPAID_BALANCE_INDEX_PAGE_VIEWED: 'Prepaid Balance -- Index Page Viewed',
  PREPAID_BALANCE_REMOVE_ACCOUNT_SAVE_CLICKED: 'Prepaid Balance -- Account Remove Save Clicked',
  PREPAID_BALANCE_REMOVE_INVITATION_SAVE_CLICKED: 'Prepaid Balance -- Invitation Remove Save Clicked',
  PREPAID_BALANCE_SHARE_WITH_TEAM_TOGGLED: 'Prepaid Balance -- Share With Team Toggled',
  PREPAID_BALANCE_VIEW_PAGE_VIEWED: 'Prepaid Balance -- View Page Viewed',

  // Project Dashboard
  PROJECT_DASHBOARD_INVITE_BUTTON_CLICKED: 'Project Dashboard -- Invite Button Clicked',
  PROJECT_DASHBOARD_INVITE_MODAL_SHOWN: 'Collaborators -- Invite Modal Shown',
  PROJECT_DASHBOARD_STATUS_FILTER_CLICKED: 'Project Dashboard -- Status Filter Clicked',
  PROJECT_DASHBOARD_APPLIED_FILTERS: 'Project Dashboard -- Applied Filters',
  PROJECT_DASHBOARD_CLEARED_FILTERS: 'Project Dashboard -- Cleared Filters',
  PROJECT_DASHBOARD_REFERRAL_SWEEPSTAKES_COPY_LINK_CLICKED: 'Project Dashboard -- Researcher Referral Sweepstakes Copy Link Button Clicked',
  PROJECT_DASHBOARD_CREATE_NEW_PROJECT_MODAL_SHOWN: 'Project Dashboard -- Create New Project Modal Shown',

  // Project Listings
  PROJECT_LISTINGS_APPLY_BUTTON_CLICKED: 'Project Listings -- Apply Button Clicked',
  PROJECT_LISTINGS_HIDE_CONNECT_SOCIAL_BUTTON_CLICKED: 'Project Listings -- Hide Connect Social Button Clicked',
  PROJECT_LISTINGS_PAGE_VIEWED: 'Project Listings -- Page Viewed',
  PROJECT_LISTINGS_REFERRALS_LINK_CLICKED: 'Project Listings -- Referrals Link Clicked',
  PROJECT_LISTINGS_RESULTS_FETCHED: 'Project Listings -- Results Fetched',
  PROJECT_LISTINGS_SEARCH_LINK_CLICKED: 'Project Listings -- Search Link Clicked',
  PROJECT_LISTINGS_SORT_DROPDOWN_CLICKED: 'Project Listings -- Sort Dropdown Clicked',

  // Recruit participants table
  RECRUIT_PARTICIPANTS_MARKED_FOR_FOLLOW_UP_FILTER_CLICKED: 'Recruit Participants -- Marked For Follow Up Filter Clicked',

  // Signature Requests
  SIGNATURE_REQUEST_CREATED: 'Signature Requests -- Signature Request Created',
  SIGNATURE_REQUEST_SIGNED: 'Signature Requests -- Signature Request Signed',

  // Survey Builder
  SURVEY_ANSWER_MOVED: 'Survey -- Move Answer',
  SURVEY_ANSWER_BATCH_ADDED: 'Survey -- Add Batch Answers',
  SURVEY_ADD_GRID_COLUMN: 'Survey -- Add Grid Column',
  SURVEY_ADD_GRID_ROW: 'Survey -- Add Grid Row',
  SURVEY_ADD_NEW_QUESTION: 'Survey -- Add New Question',
  SURVEY_ADD_SKIP_LOGIC: 'Survey -- Add Skip Logic',
  SURVEY_PARTICIPANT_POPULATION_ATTRIBUTE_CREATED: 'Survey -- Participant Population Attribute Created',
  SURVEY_DELETE_PAGE_MODAL_SHOWN: 'Survey -- Delete Page Modal Shown',
  SURVEY_DELETE_PAGE_CONFIRM_CLICKED: 'Survey -- Delete Page Confirm Clicked',
  SURVEY_DUPLICATE_PAGE_CLICKED: 'Survey -- Duplicate Page Clicked',
  SURVEY_EDIT_SKIP_LOGIC: 'Survey -- Edit Skip Logic',
  SURVEY_KEY_QUESTION_SLIDER_CLICKED: 'Survey -- Key Question Slider Clicked',
  SURVEY_MOVE_QUESTION_CONFIRM_CLICKED: 'Survey -- Move Question Confirm Clicked',
  SURVEY_MOVE_QUESTION_MODAL_SHOWN: 'Survey -- Move Question Modal Shown',
  SURVEY_PREVIEW_BUTTON_CLICKED: 'Survey -- Preview Button Clicked',
  SURVEY_QUESTION_MOVED_FROM_PAGE: 'Survey -- Question Moved From Page',
  SURVEY_REMOVE_GRID_COLUMN: 'Survey -- Remove Grid Column',
  SURVEY_REMOVE_GRID_ROW: 'Survey -- Remove Grid Row',
  SURVEY_REMOVE_QUESTION: 'Survey -- Remove Question',
  SURVEY_OTHER_OPTION_CHECKBOX_CLICKED: 'Survey -- Other Option Checkbox Clicked',
  SURVEY_REQUIRED_CHECKBOX_CLICKED: 'Survey -- Required Checkbox Clicked',
  SURVEY_UPGRADE_ACCEPTED: 'Survey -- Upgrade Accepted',
  SURVEY_UPGRADE_DISMISSED: 'Survey -- Upgrade Dismissed',
  SURVEY_UPGRADE_MODAL_SHOWN: 'Survey -- Upgrade Modal Shown',
  SURVEY_PARTICIPANT_POPULATION_ATTRIBUTE_SELECTED: 'Survey -- Participant Population Attribute Selected',
  SURVEY_RECRUIT_SOURCE_SUBMITTED: 'Survey -- Participant Recruit Source Submitted',

  TEAM_BILLING_INFO_PROJECT_LAUNCH_MODAL_SHOWN: 'Project Launch Review -- Add Billing Information Modal Shown',
  TEAM_BILLING_INFO_PROJECT_LAUNCH_MODAL_SUBMITTED: 'Project Launch Review -- Add Billing Information Modal Submitted',
  TEAM_REPORTS_LOAD: 'Team -- Reports Loaded',
  TEAM_REPORTS_CLICKED_SPENDING_TAB: 'Team -- Reports Clicked Sessions and Spending Tab',
  TEAM_REPORTS_CLICKED_ACTIVITY_TAB: 'Team -- Reports Clicked Team Activity Tab',
  TEAM_REPORTS_CLICKED_CUSTOM_TAB: 'Team -- Reports Clicked Custom Tab',
  TEAM_REPORTS_CLICKED_DOWNLOAD: 'Team -- Reports Clicked Download',
  TEAM_INVITE_URL_COPIED: 'Team -- Invite URL copied',
  TEAM_MEMBERS_INVITED: 'Team -- Members Invited',
  TEAM_MEMBERS_DEACTIVATE: 'Team -- Deactivate team member',
  TEAM_MEMBERS_REACTIVATE: 'Team -- Reactivate team member',
  TEAM_MEMBERS_VERIFIED: 'Team -- Verify team member',
  TEAM_MEMBERS_DELETE_INVITE_CLICK: 'Team -- Member Invite Delete Click',
  TEAM_MEMBERS_RESEND_INVITE_CLICK: 'Team -- Member Invite Resend Click',
  TEAM_NAME_UPDATED_FROM_TEAM_DASHBOARD: 'Team -- Name Updated From Team Page',
  TEAM_MEMBERS_VIEWED_FROM_TEAM_DASHBOARD: 'Team -- Members Viewed From Team Page',
  TEAM_NOTES_VIEWED_FROM_TEAM_DASHBOARD: 'Team -- Notes Viewed From Team Page',

  // Video Uploads
  VIDEO_UPLOADS_PARTICIPANT_FLOW_SUBMIT_BUTTON_CLICKED: 'Video Uploads Participant Flow - Submit Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_SUBMIT_BUTTON_OPENS_SKIP_MODAL_CLICKED: 'Video Uploads Participant Flow - Submit Button Opens Skip Modal Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_SKIP_MODAL_SKIP_SUBMISSION_BUTTON_CLICKED: 'Video Uploads Participant Flow - Skip Modal Skip Submission Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_SKIP_MODAL_CANCEL_BUTTON_CLICKED: 'Video Uploads Participant Flow - Skip Modal Cancel Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_ENABLE_CAMERA_BUTTON_CLICKED: 'Video Uploads Participant Flow - Enable Camera Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_RECORD_A_VIDEO_BUTTON_CLICKED: 'Video Uploads Participant Flow - Record a Video Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_START_RECORDING_BUTTON_CLICKED: 'Video Uploads Participant Flow - Start Recording Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_END_RECORDING_BUTTON_CLICKED: 'Video Uploads Participant Flow - End Recording Button Clicked',
  VIDEO_UPLOADS_PARTICIPANT_FLOW_RE_RECORD_BUTTON_CLICKED: 'Video Uploads Participant Flow - Re-record Button Clicked',

  VIDEO_UPLOADS_RESEARCHER_SETUP_VIDEO_QUESTIONS_OPTION_CHECKBOX_CLICKED: 'Video Uploads Researcher Setup - Video Questions Option Checkbox Clicked',
  VIDEO_UPLOADS_RESEARCHER_SETUP_VIDEO_QUESTIONS_OPTION_ADD_BUTTON_CLICKED: 'Video Uploads Researcher Setup - Video Questions Option Add Button Clicked',
  VIDEO_UPLOADS_RESEARCHER_SETUP_VIDEO_QUESTIONS_OPTION_SAVE_BUTTON_CLICKED: 'Video Uploads Researcher Setup - Video Questions Option Save Button Clicked',
  VIDEO_UPLOADS_RESEARCHER_SETUP_VIDEO_QUESTIONS_OPTION_CANCEL_BUTTON_CLICKED: 'Video Uploads Researcher Setup - Video Questions Option Cancel Button Clicked',
  VIDEO_UPLOADS_RESEARCHER_SETUP_VIDEO_QUESTIONS_OPTION_EDIT_BUTTON_CLICKED: 'Video Uploads Researcher Setup - Video Questions Option Edit Button Clicked',

  WORKSPACE_MOST_POPULAR_CRITERION_BUTTON_CLICKED: 'Workspace -- Most Popular Criterion Button Clicked',

  // project_workspace
  WORKSPACE_DRAFT_PARTICIPANTS_DELETE_CLICKED: 'Workspace -- Draft Participants Delete Clicked',
  WORKSPACE_DRAFT_PARTICIPANTS_DELETE_CONFIRMED: 'Workspace -- Draft Participants Delete Confirmed',

  WORKSPACE_DRAWER_BILLING_LOAD: 'Workspace -- Billing Drawer Loaded',
  WORKSPACE_DRAWER_COLLABORATION_LOAD: 'Workspace -- Collaboration Drawer Loaded',
  WORKSPACE_DRAWER_LAUNCH_LOAD: 'Workspace -- Launch Drawer Loaded',
  WORKSPACE_DRAWER_PREPARATION_INSTRUCTIONS_LOAD: 'Workspace -- Preparation Instructions Drawer Loaded',
  WORKSPACE_DRAWER_RECRUITMENT_CRITERIA_LOAD: 'Workspace -- Recruitment Criteria Drawer Loaded',
  WORKSPACE_DRAWER_RECRUITMENT_ADDITIONAL_DETAILS_LOAD: 'Workspace -- Recruitment Additional Details Drawer Loaded',
  WORKSPACE_DRAWER_SCHEDULING_LOAD: 'Workspace -- Scheduling Drawer Loaded',
  WORKSPACE_DRAWER_SCREENER_SURVEY_LOAD: 'Workspace -- Screener Survey Drawer Loaded',
  WORKSPACE_DRAWER_TASK_LOCATION_LOAD: 'Workspace -- Task Location Drawer Loaded',
  WORKSPACE_DRAWER_VIDEO_RESPONSE_LOAD: 'Workspace -- Video Response Drawer Loaded',

  WORKSPACE_MANAGE_SESSIONS_LOAD: 'Workspace -- Participant Management Sessions loaded',
  WORKSPACE_MANAGE_MOD_SESSIONS_FILTERS_CLICKED: 'Workspace -- Participant Management Moderated Session Filters clicked',
  WORKSPACE_MANAGE_PARTICIPANT_TRACKING_LOAD: 'Workspace -- Participant Management Participant Tracking loaded',
  WORKSPACE_MANAGE_PARTICIPANTS_LOAD: 'Workspace -- Participant Management Participants loaded',
  WORKSPACE_MANAGE_MESSAGES_LOAD: 'Workspace -- Participant Management Messages loaded',

  WORKSPACE_MANUAL_SAVE_EVENT: 'Workspace -- Manual Save event',

  WORKSPACE_MODAL_INTERNAL_TITLE_DESCRIPTION_LOAD: 'Workspace -- Internal Title and Description Modal loaded',

  WORKSPACE_RESEARCH_DESIGN_ACTIVITY_LOAD: 'Workspace -- Research Design Activity loaded',
  WORKSPACE_RESEARCH_DESIGN_COMMUNICATIONS_LOAD: 'Workspace -- Research Design Communications loaded',
  WORKSPACE_RESEARCH_DESIGN_INCENTIVES_LOAD: 'Workspace -- Research Design Incentives loaded',
  WORKSPACE_RESEARCH_DESIGN_RECRUITMENT_LOAD: 'Workspace -- Research Design Recruitment loaded',

  WORKSPACE_SCREENER_RESET: 'Workspace -- Screener Reset',
  WORKSPACE_SCREENER_QUESTION_DUPLICATED: 'Workspace -- Screener Question Duplicated',
  WORKSPACE_SCREENER_TEMPLATE_OPENED: 'Workspace -- Screener Template Dropdown Opened',
  WORKSPACE_SCREENER_TEMPLATE_SELECTED: 'Workspace -- Screener Template Selected',

  WORKSPACE_PROJECT_DOCUMENT_REMOVED: 'Workspace -- Signature Request Document Removed',
  WORKSPACE_PROJECT_DOCUMENT_UPLOADED: 'Workspace -- Signature Request Document Uploaded',
  WORKSPACE_PROJECT_REPLACE_DOCUMENT_BUTTON_CLICKED: 'Workspace -- Signature Request Replace Document Button Clicked',
  WORKSPACE_PROJECT_UPLOAD_DOCUMENT_BUTTON_CLICKED: 'Workspace -- Signature Request Upload Document Button Clicked',

  WORKSPACE_PROFILE_DATA_TAB_CLICKED: 'Workspace -- Profile Data Tab Clicked',
  WORKSPACE_PROFILE_OVERVIEW_TAB_CLICKED: 'Workspace -- Profile Overview Tab Clicked',
  WORKSPACE_PROFILE_SCREENER_TAB_CLICKED: 'Workspace -- Profile Screener Tab Clicked',
  WORKSPACE_PROFILE_SESSIONS_TAB_CLICKED: 'Workspace -- Profile Sessions Tab Clicked',

  WORKSPACE_EMAIL_EDIT_CLICKED: 'Workspace -- Edit Email Clicked',

  WORKSPACE_MESSAGE_THREAD_LOADED: 'Workspace -- Message Thread Loaded',
  WORKSPACE_MESSAGE_SENT_TO_PARTICIPANT: 'Workspace -- Message Sent to Participant',
  WORKSPACE_MESSAGE_SENT_TO_PARTICIPANT_BULK: 'Workspace -- Message Sent to Participant - Bulk',
  WORKSPACE_MESSAGES_BULK_MESSAGE_CLICKED: 'Workspace -- Messages Bulk Message Clicked',

  WORKSPACE_OBSERVER_SIGNUP_PAGE_COPY_LINK_CLICKED: 'Workspace -- Observer signup page copy link clicked',
  WORKSPACE_HUB_SHARE_PROJECT_LINK_CLICKED: 'Workspace -- Hub Share Project Link Clicked',

  WORKSPACE_PARTICIPANT_FRAUDULENT_REPORT: 'Workspace -- Participant Fraudulent Report',
  WORKSPACE_PARTICIPANT_FRAUDULENT_REPORT_BULK: 'Workspace -- Participant Fraudulent Report - Bulk',
  WORKSPACE_PARTICIPANT_INVITE_DOWNLOAD_CLICKED: 'Workspace -- Participant Invite Download Clicked',
  WORKSPACE_PARTICIPANT_INVITE_FILTERS_ALL_CLICKED: 'Workspace -- Participant Invite Filter All Clicked',
  WORKSPACE_PARTICIPANT_INVITE_FILTERS_APPLIED_CLICKED: 'Workspace -- Participant Invite Filter Applied Clicked',
  WORKSPACE_PARTICIPANT_INVITE_FILTERS_PENDING_CLICKED: 'Workspace -- Participant Invite Filter Pending Clicked',
  WORKSPACE_PARTICIPANT_PROFILE_STATUS_CHANGED: 'Workspace -- Participant Profile Status Changed',
  WORKSPACE_PARTICIPANT_ACTIVE_PROPOSED_RANGES_DISMISSED: 'Workspace -- Proposed Active Proposed Ranges Dismissed',
  WORKSPACE_PARTICIPANT_EXPIRED_PROPOSED_RANGES_DISMISSED: 'Workspace -- Proposed Expired Proposed Ranges Dismissed',
  WORKSPACE_PARTICIPANT_PROPOSED_RANGE_APPROVED: 'Workspace -- Proposed Range Approved',
  WORKSPACE_PARTICIPANT_TRACKING_TABLE_LOAD: 'Workspace -- Participant Tracking Table Loaded',
  WORKSPACE_PARTICIPANT_TRACKING_TABLE_TAB_CLICKED: 'Workspace -- Participant Tracking Table Tab Clicked',

  WORKSPACE_PARTICIPANTS_EXPORT_CANCELED: 'Workspace -- Participants Export canceled',
  WORKSPACE_PARTICIPANTS_EXPORT_CLICKED: 'Workspace -- Participants Export Clicked',
  WORKSPACE_PARTICIPANTS_EXPORT_REVIEW_TIER_SELECTED: 'Workspace -- Participants Export review_tier selected',
  WORKSPACE_PARTICIPANTS_EXPORT_STATUS_SELECTED: 'Workspace -- Participants Export status selected',

  WORKSPACE_PARTICIPANTS_ACTIONS_CLICKED: 'Workspace -- Participants actions clicked',
  WORKSPACE_PARTICIPANTS_ACTIONS_EXPORT_CLICKED: 'Workspace -- Participants actions export clicked',
  WORKSPACE_PARTICIPANTS_FILTERS_CLICKED: 'Workspace -- Participants filters clicked',
  WORKSPACE_PARTICIPANTS_FILTERS_APPLIED: 'Workspace -- Participants filters applied',

  WORKSPACE_PARTICIPANTS_BULK_MESSAGE_CANCELED: 'Workspace -- Participants Bulk Message Canceled',
  WORKSPACE_PARTICIPANTS_BULK_MESSAGE_CLICKED: 'Workspace -- Participants Bulk Message Clicked',

  WORKSPACE_PARTICIPANTS_RECRUIT_PARTICIPANTS_CLICKED: 'Workspace -- Participants Recruit Participants Link Clicked',
  WORKSPACE_PARTICIPANTS_REVIEW_TIER_SELECTED: 'Workspace -- Participants review_tier selected',
  WORKSPACE_PARTICIPANTS_SEARCH_TERMS_CLEARED: 'Workspace -- Participants search_terms cleared',
  WORKSPACE_PARTICIPANTS_SEARCH_TERMS_ENTERED: 'Workspace -- Participants search_terms entered',
  WORKSPACE_PARTICIPANTS_STATUS_SELECTED: 'Workspace -- Participants status selected',

  WORKSPACE_REQUEST_TO_CLOSE_CONFIRMATION_SUBMITTED: 'Workspace -- Request to close confirmation submitted',

  WORKSPACE_SCHEDULING_CONFIRMED_SESSION_REMOVE_CLICKED: 'Workspace/Scheduling - Confirmed Session Remove Clicked',
  WORKSPACE_SCHEDULING_CONFIRMED_SESSION_RESCHEDULE_CLICKED: 'Workspace/Scheduling - Confirmed Session Reschedule Clicked',
  WORKSPACE_SCHEDULING_DNS_RESCHEDULE_CLICKED: 'Workspace/Scheduling - DNS Reschedule Clicked',

  WORKSPACE_SCREENER_LOAD: 'Workspace -- Screener Page Loaded',
  WORKSPACE_SCREENER_EDIT_BUTTON_CLICKED: 'Workspace -- Edit Screener Button Clicked',
  // Note that this is the one wrong spelling, but leaving it to not disrupt analytics tracking
  WORKSPACE_SCREENER_EDIT_CANCELED: 'Workspace -- Edit Screener Cancelled',
  WORKSPACE_SCREENER_CONTINUE_TO_EDIT: 'Workspace -- Continue to Edit Screener Clicked',
  WORKSPACE_SCREENER_EDITED: 'Workspace -- Screener Edited',

  WORKSPACE_SESSIONS_MARK_PARTICIPANT_COMPLETED_CLICKED: 'Workspace -- Sessions Mark Participant Completed Clicked',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_COMPLETED_CLICKED_BULK: 'Workspace -- Sessions Mark Participant Completed Clicked - Bulk',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_DID_NOT_SHOW_CLICKED: 'Workspace -- Sessions Mark Participant Did Not Show Clicked',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_FEEDBACK_CLICKED: 'Workspace -- Sessions Mark Participant Feedback Clicked',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_FEEDBACK_CLICKED_BULK: 'Workspace -- Sessions Mark Participant Feedback Clicked - Bulk',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_FEEDBACK_SUBMITTED: 'Workspace -- Sessions Mark Participant Feedback Submitted',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_FEEDBACK_SUBMITTED_BULK: 'Workspace -- Sessions Mark Participant Feedback Submitted - Bulk',
  WORKSPACE_SESSIONS_MARK_PARTICIPANT_FRAUDULENT_CLICKED: 'Workspace -- Sessions Mark Participant Fraudulent Clicked',
  WORKSPACE_SESSIONS_NO_SHOW_CLICKED_BULK: 'Workspace -- Sessions Mark No Show Clicked - Bulk',
  WORKSPACE_SESSIONS_REPORT_PARTICIPANT_CLICKED: 'Workspace -- Sessions Report Participant Clicked',
  WORKSPACE_SESSIONS_REMOVED_CLICKED: 'Workspace -- Sessions Remove Clicked',
  WORKSPACE_VIEW_MESSAGES_CLICKED: 'Workspace -- View Messages Button Clicked',

  WORKSPACE_SESSIONS_EDIT_SESSION_CLICKED: 'Workspace -- Sessions Edit Session Clicked',
  WORKSPACE_SESSIONS_EDIT_SESSION_SAVE_CLICKED: 'Workspace -- Sessions Edit Session Save Clicked',
  WORKSPACE_SESSIONS_EDIT_SESSION_CANCEL_CLICKED: 'Workspace -- Sessions Edit Session Cancel Clicked',

  STAFF_CLEARBIT_DATA_CLICKED: 'Staff -- Workspace -- View Clearbit Data Clicked',

  // Staff Participant Profile
  STAFF_PARTICIPANT_PROFILE_SCREENER_RESPONSE_DELETE_CLICKED: 'Staff Participant Profile -- Screener Response Delete Clicked',

  // SUBSCRIPTIONS
  SUBSCRIPTION_MODAL_LOADED: 'Subscription Modal -- Loaded',
  SUBSCRIPTION_MODAL_UPGRADE_SUBSCRIPTION_CANCELED: 'Subscription Modal -- Cancel Button Clicked',
  SUBSCRIPTION_MODAL_UPGRADE_SUBSCRIPTION_REQUESTED: 'Subscription Modal -- Upgrade Button Clicked',
  SUBSCRIPTION_MODAL_UPGRADE_SUBSCRIPTION_SUCCESS: 'Subscription Modal -- Upgrade Successful',

  ADD_CARD_BUTTON_CLICKED: 'Add Card Button -- Clicked',

  // Socials Shares
  FACEBOOK_SHARE_BUTTON_CLICKED: 'Facebook Share Button Clicked',
  TWITTER_SHARE_BUTTON_CLICKED: 'Twitter Share Button Clicked',

  // Navigation
  NAVIGATION_ACCOUNT_CLICKED: 'Navigation -- Account -- Clicked',
  NAVIGATION_ACCOUNT_POPOVER_OPENED: 'Navigation -- Account Popover -- Opened',
  NAVIGATION_PARTICIPANTS_CLICKED: 'Navigation -- Participants -- Clicked',
  NAVIGATION_PLANS_CLICKED: 'Navigation -- Explore plans -- Clicked',
  NAVIGATION_PROJECTS_CLICKED: 'Navigation -- Projects -- Clicked',
  NAVIGATION_SIGN_OUT_CLICKED: 'Navigation -- Sign out -- Clicked',
  NAVIGATION_SUPPORT_CLICKED: 'Navigation -- Support -- Clicked',
  NAVIGATION_TEAMS_CLICKED: 'Navigation -- Team -- Clicked',

  // Team Project Templates
  TEMPLATE_MODAL_REMOVE_CLICK: 'Template modal -- Remove template -- Clicked',
  TEMPLATE_MODAL_USE_CLICK: 'Templates modal -- Use template -- Clicked',
  TEMPLATE_MODAL_OPENED: 'Templates modal -- Opened',
  TEMPLATE_ADD_CLICK: 'Project -- Mark as template -- Clicked',
  TEMPLATE_REMOVE_CLICK: 'Project -- Unmark as template -- Clicked',
} as const;

export const upgradeFeatures = {
  ADVANCED_SCREENING: 'Double Screening',
  BRANDING: 'Custom Branding',
  DATA_CONSENT_SETTINGS: 'Data Consent Settings',
  DOCUMENT_SIGNING: 'Document Signing',
  DOMAINS: 'Domain Settings',
  EMAIL_PROFILES: 'Sender Profiles',
  EMAIL_TEMPLATES: 'Email Template Sets',
  UNLIMITED_POPULATION: 'Unlimited Population',
  POPULATION_INVITE_FILTERS: 'Hub Invite Rules',
  RECRUIT: 'Recruit Participants',
  SCREENER_LIBRARY: 'Screener Library',
  ZOOM: 'Zoom',
} as const;
