import {
  SORT_DIRECTION_DESCENDING,
} from 'researcher/participant_search/filtered_view/column_actions/constants';

const DELIMITER = ',';

export const sortAscendingRegex = columnName => new RegExp(`(,|^)${columnName}(,|$)`, 'gi');

export const sortDescendingRegex = columnName => new RegExp(`(,|^)-${columnName}(,|$)`, 'gi');

export const sortAscendingAndDescendingRegex = columnName =>
  new RegExp(`(,|^)-?${columnName}(,|$)`, 'gi');

export const removeColumnFromSortParam = (columnName, sortParam) => {
  if (!sortParam) return '';

  return sortParam
    .replace(sortAscendingAndDescendingRegex(columnName), DELIMITER)
    .split(DELIMITER)
    .filter(Boolean)
    .join(DELIMITER);
};

export const updateColumnInSortParam = (columnName, sortParam, sortDirection) => {
  const currentSortParam = removeColumnFromSortParam(columnName, sortParam);

  if (!sortDirection) return currentSortParam;

  const newSortParam = sortDirection === SORT_DIRECTION_DESCENDING ? `-${columnName}` : columnName;

  return currentSortParam ? `${newSortParam},${currentSortParam}` : newSortParam;
};
