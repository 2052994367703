export function closest(element, selector) {
  let el = element;

  while (el) {
    if (el.matches(selector)) {
      return el;
    }
    el = el.parentElement;
  }

  return null;
}

export function innerHeight(selector) {
  const element = document.querySelector(selector);
  const computedStyle = getComputedStyle(element);

  return element.clientHeight -
    parseFloat(computedStyle.paddingTop) -
    parseFloat(computedStyle.paddingBottom);
}
