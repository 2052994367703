import * as Constants from 'common/components/survey_builder/constants';

import {
  buildAnswer,
  insertIntoArray,
  removeFromArray,
} from './utilities';

import { SkipLogicConditionUtilities } from './skip_logic_condition_utilities';
import { Answer, Question, Survey } from './types';

const addAnswer = (questionUUID: string, prevAnswerUUID: string, survey: Survey) => {
  const question = { ...survey.questions[questionUUID] } as Question;
  const newAnswer = buildAnswer(question.pick);

  question.answerIds = insertIntoArray(question.answerIds, newAnswer.uuid, prevAnswerUUID);

  return {
    answers: { ...survey.answers, [newAnswer.uuid]: newAnswer },
    questions: { ...survey.questions, [question.uuid]: question },
  };
};

const addAnswers = (questionUUID: string, answerUUID: string, answers: string[], survey: Survey) => {
  const question = { ...survey.questions[questionUUID] } as Question;

  let currentPrevAnswerUUID: string;

  const newAnswers: { [key: string]: Answer } = {};

  answers.filter(answer => !!answer).forEach((answer, index) => {
    if (index === 0) {
      currentPrevAnswerUUID = answerUUID;
      newAnswers[answerUUID] = { ...survey.answers[answerUUID], text: answer };
    } else {
      const newAnswer = buildAnswer(question.pick);
      question.answerIds = insertIntoArray(question.answerIds, newAnswer.uuid, currentPrevAnswerUUID);
      currentPrevAnswerUUID = newAnswer.uuid;
      newAnswers[newAnswer.uuid] = { ...newAnswer, text: answer };
    }
  });

  return {
    answers: { ...survey.answers, ...newAnswers },
    questions: { ...survey.questions, [question.uuid]: question },
  };
};

const addOtherAnswer = (questionUUID: string, survey: Survey) => {
  const question = { ...survey.questions[questionUUID] } as Question;
  const newAnswer = buildAnswer(
    question.pick,
    {
      responseClass: Constants.RESPONSE_CLASS_OTHER_AND_STRING,
      text: 'Other',
    },
  );

  const updatedQuestion = {
    ...question,
    answerIds: insertIntoArray(question.answerIds, newAnswer.uuid),
  };

  return {
    answers: { ...survey.answers, [newAnswer.uuid]: newAnswer },
    questions: { ...survey.questions, [question.uuid]: updatedQuestion },
  };
};

const moveAnswer = (questionId: string, activeAnswerId: string, overAnswerId: string, survey: Survey) => {
  const question = { ...survey.questions[questionId] } as Question;

  const { answerIds } = question;
  const currentPosition = answerIds.indexOf(activeAnswerId);
  const targetPosition = answerIds.indexOf(overAnswerId);
  if (currentPosition === targetPosition) return survey;

  answerIds.splice(currentPosition, 1);
  answerIds.splice(targetPosition, 0, activeAnswerId);

  return {
    answers: survey.answers,
    questions: {
      ...survey.questions,
      [questionId]: {
        ...survey.questions[questionId],
        answerIds,
      },
    },
  };
};

const removeAnswer = (questionUUID: string, answerUUID: string, survey: Survey) => {
  const question = { ...survey.questions[questionUUID] } as Question;

  if (question.answerIds.length === 1) return survey;

  const answers = { ...survey.answers };

  // Remove id from question answerIds
  question.answerIds = removeFromArray(question.answerIds, answerUUID);

  // Remove from answers
  delete answers[answerUUID];

  const updatedSurvey = {
    ...survey,
    answers,
    questions: {
      ...survey.questions,
      [questionUUID]: question,
    },
  };

  // Remove any connected skip logic conditions
  const skipLogicUpdates = SkipLogicConditionUtilities.removeAnswerConditions(questionUUID, answerUUID, updatedSurvey);

  return {
    ...updatedSurvey,
    ...skipLogicUpdates,
  }
};

const updateAnswer = (answerUUID: string, updates: Answer, survey: Survey) => ({
  answers: {
    ...survey.answers,
    [answerUUID]: {
      ...survey.answers[answerUUID],
      ...updates,
      errors: {},
    },
  },
});

export const AnswerUtilities = {
  add: addAnswer,
  addBatch: addAnswers,
  addOther: addOtherAnswer,
  move: moveAnswer,
  remove: removeAnswer,
  update: updateAnswer,
};
