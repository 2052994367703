import * as propTypes from 'lib/prop_types';

export const projectAvailabilityRangePropType = propTypes.shape({
  calendarEventId: propTypes.string,
  datetimeDisplay: propTypes.string,
  endDate: propTypes.string,
  endTime: propTypes.string,
  hasPassed: propTypes.bool,
  id: propTypes.string.isRequired,
  numScheduled: propTypes.number,
  numSlots: propTypes.number,
  startDate: propTypes.string,
  startTime: propTypes.string,
  timeDisplay: propTypes.string,
  uuid: propTypes.string,
});
