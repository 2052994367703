import generateUUID from 'lib/generate_uuid';

import { SkipLogicUtilities } from './skip_logic_utilities';
import {
  Answer,
  FormType,
  Question,
  QuestionGroup,
  Section,
  Survey,
  SurveyUpdate,
} from './types';

import {
  buildAnswersForQuestion,
  buildQuestion,
  buildSection,
  cleanSectionTitles,
  insertIntoArray,
  isQuestionGroup,
  removeFromArray,
  removeQuestionAndAnswers,
} from './utilities';

const addSection = (prevSectionUUID: string, survey: Survey, formType: FormType) => {
  const newSection = buildSection() as Section;
  const sections = {
    ...survey.sections,
    [newSection.uuid]: newSection,
  };

  // Build a seed question for the new section
  const newQuestion = buildQuestion() as Question;
  newSection.questionIds.push(newQuestion.uuid);

  // Build seed answers for the new question
  const newAnswers = buildAnswersForQuestion(newQuestion) as Answer[];
  const answers = { ...survey.answers };
  newAnswers.forEach((answer) => {
    answers[answer.uuid] = answer;
    newQuestion.answerIds.push(answer.uuid);
  });

  const sectionIds = insertIntoArray(survey.sectionIds, newSection.uuid, prevSectionUUID);

  // Reset all the page titles to be the correct names based on their position
  cleanSectionTitles(sectionIds, sections, formType);

  return {
    answers,
    questions: { ...survey.questions, [newQuestion.uuid]: newQuestion },
    sectionIds,
    sections,
  };
};

const duplicateSection = (sectionUUID: string, survey: Survey, formType: FormType) => {
  const newSection = buildSection() as Section;

  const sectionToDuplicate = survey.sections[sectionUUID];
  const questionIdsToDuplicate = sectionToDuplicate.questionIds;

  const newAnswers = { ...survey.answers };
  const newQuestions = { ...survey.questions };
  const newQuestionIds: string[] = [];

  questionIdsToDuplicate.forEach((questionUUID) => {
    const newQuestion = {
      ...survey.questions[questionUUID],
      errors: {},
      isEditing: false,
      uuid: generateUUID(),
    } as Question | QuestionGroup;
    delete newQuestion.participantPopulationAttributeId;

    if (isQuestionGroup(newQuestion)) {
      // Copy all the question's questions and put them into the new question map
      newQuestion.questionIds = newQuestion.questionIds.map((questionId) => {
        const newQuestionGroupQuestion = {
          ...survey.questions[questionId],
          errors: {},
          isEditing: false,
          questionGroupId: newQuestion.uuid,
          uuid: generateUUID(),
        } as Question;

        // Copy all the question group question's answers and put them into the new answers map
        newQuestionGroupQuestion.answerIds = newQuestionGroupQuestion.answerIds.map((answerId) => {
          const newAnswer = {
            ...survey.answers[answerId],
            errors: {},
            uuid: generateUUID(),
          };
          newAnswers[newAnswer.uuid] = newAnswer;

          return newAnswer.uuid;
        });

        newQuestions[newQuestionGroupQuestion.uuid] = newQuestionGroupQuestion;

        return newQuestionGroupQuestion.uuid;
      });
    } else {
      // Copy all the question's answers and put them into the new answers map
      newQuestion.answerIds = newQuestion.answerIds.map((answerId) => {
        const newAnswer = {
          ...survey.answers[answerId],
          errors: {},
          uuid: generateUUID(),
        };

        newAnswers[newAnswer.uuid] = newAnswer;

        return newAnswer.uuid;
      });
    }

    newQuestionIds.push(newQuestion.uuid);

    newQuestions[newQuestion.uuid] = newQuestion;
  });

  newSection.questionIds = newQuestionIds;

  const newSections = {
    ...survey.sections,
    [newSection.uuid]: newSection,
  };

  const sectionIds = insertIntoArray(survey.sectionIds, newSection.uuid, sectionUUID);

  cleanSectionTitles(sectionIds, newSections, formType);

  return {
    answers: newAnswers,
    questions: newQuestions,
    sectionIds,
    sections: newSections,
  };
};

const removeSectionQuestionsAndAnswers = (sectionUUID: string, survey: Survey) => {
  let updates = {};
  const updatedSurvey = { ...survey };

  const section = updatedSurvey.sections[sectionUUID];
  const { questions } = updatedSurvey;

  section.questionIds.forEach(questionId => {
    updates = {
      ...updates,
      ...removeQuestionAndAnswers(questions[questionId], { ...updatedSurvey, ...updates }),
    }
  });

  return updates;
}

const removeAnySkipLogicsTargetingSection = (sectionUUID: string, survey: Survey) => {
  const updatedSurvey = { ...survey };
  let updates: SurveyUpdate = {};

  Object.values(updatedSurvey.skipLogics)
    .filter(skipLogic => skipLogic.targetSectionId === sectionUUID) // Grab skip logics targeting the section
    .forEach((skipLogic) => {
      const targetSection = Object.values(updatedSurvey.sections).find(
        surveySection => surveySection.skipLogicIds.includes(skipLogic.uuid),
      );

      if (!targetSection) return;

      // Remove the skip logics
      const removeSkipLogicUpdates = SkipLogicUtilities.remove(
        targetSection.uuid,
        skipLogic.uuid,
        { ...updatedSurvey, ...updates },
      );

      updates = {
        ...updates,
        ...removeSkipLogicUpdates,
        sections: {
          ...updatedSurvey.sections,
          [targetSection.uuid]: {
            ...updatedSurvey.sections[targetSection.uuid],
            ...removeSkipLogicUpdates.sections[targetSection.uuid], // updated list of skipLogicIds
          },
        },
      };
    });

  return updates;
}

const removeSectionSkipLogics = (sectionUUID: string, survey: Survey) => {
  const updatedSurvey = { ...survey };
  let updates: SurveyUpdate = {};

  const section = updatedSurvey.sections[sectionUUID];
  section.skipLogicIds.forEach(skipLogicId => {
    updates = {
      ...updates,
      ...SkipLogicUtilities.remove(sectionUUID, skipLogicId, { ...updatedSurvey, ...updates }),
    }
  });

  return updates;
}

const removeSection = (sectionUUID: string, survey: Survey, formType: FormType) => {
  let updatedSurvey = {
    ...survey,
    ...removeSectionSkipLogics(sectionUUID, survey),
  }

  updatedSurvey = {
    ...updatedSurvey,
    ...removeAnySkipLogicsTargetingSection(sectionUUID, updatedSurvey),
  }

  updatedSurvey = {
    ...updatedSurvey,
    ...removeSectionQuestionsAndAnswers(sectionUUID, updatedSurvey),
  }

  const sectionIds = removeFromArray(updatedSurvey.sectionIds, sectionUUID);
  const sections = { ...updatedSurvey.sections };

  delete sections[sectionUUID];

  // Reset all the page titles to be the correct names based on their position
  cleanSectionTitles(sectionIds, sections, formType);

  return {
    ...updatedSurvey,
    sectionIds,
    sections,
  }
};

export const SectionUtilities = {
  add: addSection,
  duplicate: duplicateSection,
  remove: removeSection,
};
