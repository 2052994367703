import { Survey, SurveyUpdate } from './types';

import { SkipLogicUtilities } from './skip_logic_utilities';
import {
  removeFromArray,
} from './utilities';

const removeSkipLogicConditions = (skipLogicUUID: string, skipLogicConditionUUIDs: string[], survey: Survey) => {
  const skipLogic = { ...survey.skipLogics[skipLogicUUID] };

  const skipLogicConditions = { ...survey.skipLogicConditions };

  // Remove the condition id from the skip logic
  // AND remove the condition from the survey
  skipLogicConditionUUIDs.forEach((skipLogicConditionUUID) => {
    skipLogic.conditionIds = removeFromArray(skipLogic.conditionIds, skipLogicConditionUUID);
    delete skipLogicConditions[skipLogicConditionUUID];
  });

  // Remove the whole skip logic if there are no other conditions
  if (skipLogic.conditionIds.length === 0) {
    const section = Object.values(survey.sections).find(
      sect => sect.skipLogicIds.includes(skipLogicUUID),
    );

    if (section) {
      return SkipLogicUtilities.remove(section.uuid, skipLogicUUID, survey);
    }
  }

  return {
    skipLogicConditions,
    skipLogics: { ...survey.skipLogics, [skipLogic.uuid]: skipLogic },
  };
};

const removeAnswerSkipLogicConditions = (questionUUID: string, answerUUID: string, survey: Survey) => {
  const section = Object.values(survey.sections).find(
    surveySection => surveySection.questionIds.includes(questionUUID),
  );

  let updates: SurveyUpdate = {};

  section?.skipLogicIds.forEach((skipLogicId) => {
    const conditionIds = survey.skipLogics[skipLogicId].conditionIds.filter(
      conditionId => survey.skipLogicConditions[conditionId].answerId === answerUUID,
    );

    updates = {
      ...updates,
      ...removeSkipLogicConditions(skipLogicId, conditionIds, { ...survey, ...updates }),
    };
  });

  return updates;
}

const removeQuestionSkipLogicConditions = (sectionUUID: string, questionUUID: string, survey: Survey) => {
  const section = { ...survey.sections[sectionUUID] };

  let updates: SurveyUpdate = {};

  section.skipLogicIds.forEach((skipLogicId) => {
    const conditionIds = survey.skipLogics[skipLogicId].conditionIds.filter(
      conditionId => survey.skipLogicConditions[conditionId].questionId === questionUUID,
    );

    updates = {
      ...updates,
      ...removeSkipLogicConditions(skipLogicId, conditionIds, { ...survey, ...updates }),
    };
  });

  return updates;
}

export const SkipLogicConditionUtilities = {
  removeConditions: removeSkipLogicConditions,
  removeAnswerConditions: removeAnswerSkipLogicConditions,
  removeQuestionConditions: removeQuestionSkipLogicConditions,
};
