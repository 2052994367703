import { recordsByDig } from 'lib/collections';
import curry from 'lib/curry';
import { ADDRESSES, DEFAULT_FROM_NAME } from 'lib/generated_constants/emails';

const PERSONAL_PROFILES = 'personal profiles';
const OTHER_PROFILES = 'other profiles';
const COLLABORATOR_PROFILES = 'collaborator profiles';

export const ADD_PROFILE_OPTION = 'add';

const DEFAULT_OPTION = {
  label: `${DEFAULT_FROM_NAME} (${ADDRESSES.RESEARCH_EMAIL})`,
  value: null,
};

const ADD_NEW_OPTION = {
  options: [{
    label: '+ Create a profile',
    value: ADD_PROFILE_OPTION,
  }],
};

const PROFILE_GROUPS = [PERSONAL_PROFILES, COLLABORATOR_PROFILES, OTHER_PROFILES];

const optionFromProfile = ({
  email, fromName, id, isVerified,
}) => ({
  label: `${fromName} (${email})${isVerified ? '' : ' [Unverified]'}`,
  value: Number.parseInt(id),
});

const optionsFromProfiles = (senderProfiles, kind) => {
  const relevantProfiles = senderProfiles[kind] || [];
  const options = relevantProfiles.map(optionFromProfile);

  return { label: kind, options };
};

const recordsByOwnerKind = (records, authUserId, collaboratorIds) => {
  const collaboratorIdsSet = new Set(collaboratorIds);
  const currentUserId = Number(authUserId);
  const newRecords = records.map((record) => {
    const profileOwner = record.emailProfileOwner;
    let ownerCategoryValue = OTHER_PROFILES;

    if (profileOwner?.id === currentUserId) {
      ownerCategoryValue = PERSONAL_PROFILES;
    } else if (collaboratorIdsSet.has(profileOwner?.id)) {
      ownerCategoryValue = COLLABORATOR_PROFILES;
    }
    return { ...record, profileOwnerCategory: ownerCategoryValue };
  });
  const recordsByKind = recordsByDig(newRecords, 'Profile Owner', 'profileOwnerCategory');
  const curriedOptions = curry(optionsFromProfiles);
  return curriedOptions(recordsByKind);
};

export const senderProfileReadOnlyOptionReducer = (emailProfiles, authUserId, collaboratorIds) => {
  const optionsForKind = recordsByOwnerKind(emailProfiles, authUserId, collaboratorIds);
  const groupedProfileOptions = PROFILE_GROUPS.map(optionsForKind);

  groupedProfileOptions[2].options.unshift(DEFAULT_OPTION);

  return groupedProfileOptions.filter(obj => !!obj.options);
};

export const senderProfileOptionReducer = (emailProfiles, authUserId, collaboratorIds) =>
  senderProfileReadOnlyOptionReducer(emailProfiles, authUserId, collaboratorIds).concat(ADD_NEW_OPTION);

export const senderProfileOptionSelector = (senderProfiles, emailProfileId) => {
  const flattened = senderProfiles.reduce((acc, optionGroup) => {
    acc.push(...optionGroup.options);
    return acc;
  }, []);

  return flattened.find(option => option.value === emailProfileId);
};
