import http, { FormatResponse } from 'lib/http';
import * as routes from 'lib/routes';

export default class TeamService {
  static async fetchInvitations(teamId) {
    const response = await http.get(routes.api_team_invites_path({ team_id: teamId }));
    return this.parseResponse(response);
  }

  static async fetchMembers(teamId, showDeactivated = false, excludeTeammateRole = false) {
    const params = {
      team_id: teamId,
      show_deactivated: showDeactivated,
      exclude_teammate_role: excludeTeammateRole,
    };
    const response = await http.get(routes.api_team_members_path(params));
    return this.parseResponse(response);
  }

  static async fetchTeam(id) {
    const response = await http.get(routes.api_team_path({ id }));
    return this.parseResponse(response);
  }

  static async fetchTeamsForOrganization(id) {
    const response = await http.get(routes.api_organization_teams_path({ id }));
    return this.parseResponse(response);
  }

  static async deleteInvite(teamId, inviteId) {
    const deletePath = routes.api_team_invite_path(inviteId, { team_id: teamId });
    await http.delete(deletePath);
  }

  static async invite(teamId, emails, projectTemplateId = undefined) {
    const response = await http.post(
      routes.api_team_invites_path({ team_id: teamId }),
      { data: { attributes: { emails, projectTemplateId } } },
    );
    return this.parseResponse(response);
  }

  static async loadTeam(id, showDeactivatedTeamMembers = false) {
    const [invitations, members, team] = await Promise.all([
      this.fetchInvitations(id),
      this.fetchMembers(id, showDeactivatedTeamMembers),
      this.fetchTeam(id),
    ]);

    return { invitations, members, team };
  }

  static parseResponse(response) {
    return FormatResponse.jsonApi({ includeMeta: true })(response).data;
  }

  static async updateTeam(id, attributes) {
    const response = await http.patch(
      routes.api_team_path({ id }),
      { data: { attributes } },
    );
    return this.parseResponse(response);
  }
}
