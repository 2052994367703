import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Keep this eslint-disable here. FontAwesome imports should only be happening
// in this file and nowhere else in the codebase.
//
// Any direct import from '@fortawesome/pro-regular-svg-icons' will create bloat in
// our overall bundle size.

/* eslint-disable no-restricted-imports */
import { faAddressBook } from '@fortawesome/pro-regular-svg-icons/faAddressBook';
import { faAddressCard } from '@fortawesome/pro-regular-svg-icons/faAddressCard';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons/faBadgeCheck';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons/faBarsFilter';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faBellSlash } from '@fortawesome/pro-regular-svg-icons/faBellSlash';
import { faBolt } from '@fortawesome/pro-regular-svg-icons/faBolt';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
import { faBookOpen } from '@fortawesome/pro-regular-svg-icons/faBookOpen';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons/faBullseyeArrow';
import { faBullseyePointer } from '@fortawesome/pro-regular-svg-icons/faBullseyePointer';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons/faCalendarTimes';
import { faChartPie } from '@fortawesome/pro-regular-svg-icons/faChartPie';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronsLeft } from '@fortawesome/pro-regular-svg-icons/faChevronsLeft';
import { faChevronsRight } from '@fortawesome/pro-regular-svg-icons/faChevronsRight';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { faClipboardList } from '@fortawesome/pro-regular-svg-icons/faClipboardList';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons/faClockRotateLeft';
import { faClone } from '@fortawesome/pro-regular-svg-icons/faClone';
import { faCloudUploadAlt } from '@fortawesome/pro-regular-svg-icons/faCloudUploadAlt';
import { faCodeBranch } from '@fortawesome/pro-regular-svg-icons/faCodeBranch';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard';
import { faDesktop } from '@fortawesome/pro-regular-svg-icons/faDesktop';
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons/faEllipsis';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons/faFileImage';
import { faFileLines } from '@fortawesome/pro-regular-svg-icons/faFileLines';
import { faFileSignature } from '@fortawesome/pro-regular-svg-icons/faFileSignature';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons/faFileUpload';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faFlag } from '@fortawesome/pro-regular-svg-icons/faFlag';
import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons/faFloppyDisk';
import { faFont } from '@fortawesome/pro-regular-svg-icons/faFont';
import { faGear } from '@fortawesome/pro-regular-svg-icons/faGear';
import { faGift } from '@fortawesome/pro-regular-svg-icons/faGift';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faHandshake } from '@fortawesome/pro-regular-svg-icons/faHandshake';
import { faHashtag } from '@fortawesome/pro-regular-svg-icons/faHashtag';
import { faHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faInbox } from '@fortawesome/pro-regular-svg-icons/faInbox';
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey';
import { faLaptop } from '@fortawesome/pro-regular-svg-icons/faLaptop';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faMagic } from '@fortawesome/pro-regular-svg-icons/faMagic';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons/faMinusCircle';
import { faMoneyBillWave } from '@fortawesome/pro-regular-svg-icons/faMoneyBillWave';
import { faPalette } from '@fortawesome/pro-regular-svg-icons/faPalette';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons/faShareAlt';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faSlidersUp } from '@fortawesome/pro-solid-svg-icons/faSlidersUp';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faStickyNote } from '@fortawesome/pro-regular-svg-icons/faStickyNote';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faWindow } from '@fortawesome/pro-regular-svg-icons/faWindow';

library.add(faAddressBook);
library.add(faAddressCard);
library.add(faBell);
library.add(faBellSlash);
library.add(faBullhorn);
library.add(faCalendarAlt);
library.add(faCalendarCheck);
library.add(faCircleCheck);
library.add(faCheckSquare);
library.add(faClock);
library.add(faCopy);
library.add(faCreditCard);
library.add(faEnvelope);
library.add(faFileAlt);
library.add(faFloppyDisk);
library.add(faListAlt);
library.add(faPaperPlane);
library.add(faQuestionCircle);
library.add(faStickyNote);
library.add(faTrashAlt);
library.add(faUser);
library.add(faLaptop);

export {
  faAddressBook,
  faAddressCard,
  faBadgeCheck,
  faBan,
  faBarsFilter,
  faBell,
  faBellSlash,
  faBolt,
  faBookmark,
  faBookOpen,
  faBullhorn,
  faBullseyeArrow,
  faBullseyePointer,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDays,
  faCalendarTimes,
  faChartPie,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faSearch,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleInfo,
  faClipboardList,
  faClock,
  faClockRotateLeft,
  faClone,
  faCloudUploadAlt,
  faCodeBranch,
  faCopy,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDotCircle,
  faEllipsis,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileImage,
  faFileLines,
  faFileSignature,
  faFileUpload,
  faFilter,
  faFlag,
  faFloppyDisk,
  faFont,
  faGear,
  faGift,
  faGlobe,
  faHandshake,
  faHashtag,
  faHistory,
  faInbox,
  faKey,
  faLink,
  faListAlt,
  faLock,
  faMagic,
  faMinus,
  faMinusCircle,
  faMoneyBillWave,
  faPalette,
  faPaperPlane,
  faPen,
  faPencil,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faShareAlt,
  faSlidersUp,
  faSpinner,
  faStar,
  faStickyNote,
  faSyncAlt,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUser,
  faUserPlus,
  faUsers,
  faWindow,
  faLaptop,
};

// This will make it so we automatically check the DOM for updates which allows
// us to use CSS selectors to create icons outside of React.
dom.watch();
