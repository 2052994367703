export const maxFileSize = { size: 5000000, displayText: '5MB' };
export const UI_EMERALD_EMAIL_THEME_ID = 'default';
export const PLAIN_TEXT_EMAIL_THEME_ID = 'unstyled';

const staticTheme = {
  canUpdate: true,
  id: undefined,
};

export const uiBlue = {
  ...staticTheme,
  name: 'UI Emerald',
  id: UI_EMERALD_EMAIL_THEME_ID,
};

export const plainText = {
  ...staticTheme,
  name: 'Plain text',
  id: PLAIN_TEXT_EMAIL_THEME_ID,
};
