import { JsonApiDataStore } from 'jsonapi-datastore';

import http from 'lib/http';
import * as routes from 'lib/routes';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_NUMBER = 1;

export default class OccupationService {
  static async search(search = '', pagination = {}) {
    const { size, number } = pagination;
    const response = await http.get(routes.api_occupations_path({
      page: {
        size: size || DEFAULT_PAGE_SIZE,
        number: number || DEFAULT_PAGE_NUMBER,
      },
      search,
    }));

    return OccupationService.parseResponseWithMeta(response);
  }

  static parseResponseWithMeta(response) {
    return new JsonApiDataStore().syncWithMeta(response);
  }

  static parseResponse(response) {
    return new JsonApiDataStore().sync(response);
  }
}
