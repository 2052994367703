import { recordsByTeamName, sortByStringKey } from 'lib/collections';
import * as routes from 'lib/routes';

export const PROJECT_CUSTOM_EMAIL_TABLE_TYPE_ALL = 'all';
export const PROJECT_CUSTOM_EMAIL_TABLE_TYPE_DEFAULTS = 'defaults';

export async function fetchDefaultEmailSets(teamId, setDefaultEmailSets, get) {
  const url = routes.api_team_settings_path({
    include: 'default_email_sets',
    team_id: teamId,
  });
  let defaultEmailSets;
  try {
    ({ defaultEmailSets } = await get(url));
  } catch (e) {
    defaultEmailSets = [];
  }
  setDefaultEmailSets(defaultEmailSets);
}

export function isOneOfDefaultEmailSets(emailSetId, defaultEmailSets) {
  return defaultEmailSets.some(defaultEmailSet => defaultEmailSet.id === emailSetId);
}

export const projectCustomEmailSetOptionReducer = (projectCustomEmailSets) => {
  const groupedOptions = Object.entries(recordsByTeamName(projectCustomEmailSets)).reduce(
    (acc, pair) => {
      const [teamName, emailSets] = pair;

      const emailSetOptions = sortByStringKey(emailSets, 'name').map(
        emailSet => ({ label: emailSet.name, value: Number.parseInt(emailSet.id) }),
      );

      acc.push({ label: teamName, options: emailSetOptions });

      return acc;
    },
    [],
  );

  return [...sortByStringKey(groupedOptions, 'label')];
};

export const projectCustomEmailSetOptionSelector =
  (projectCustomEmailSetOptions, selectedProjectCustomEmailSetId) => {
    const flattened = projectCustomEmailSetOptions.reduce((acc, optionGroup) => {
      acc.push(...optionGroup.options);

      return acc;
    }, []);

    return flattened.find(option => option.value === selectedProjectCustomEmailSetId);
  };
