/*
 An "almost cross-browser" solution via:
 https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
 */
function addNavigateAwayWarning({
  message = 'You have unsaved changes, are you sure you want to leave?',
  showWarning = () => true,
}) {
  const navigateAwayListener = (event) => {
    if (showWarning()) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = message; // Gecko, Trident, Chrome 34+
      return message; // Gecko, WebKit, Chrome <34
    }

    return undefined;
  };

  window.addEventListener('beforeunload', navigateAwayListener);

  return navigateAwayListener;
}

function cleanupNavigateAwayWarning(listener) {
  window.removeEventListener('beforeunload', listener);
}

module.exports = {
  listen: addNavigateAwayWarning,
  cleanup: cleanupNavigateAwayWarning,
};
