/* Allows you to listen for clicks outside of a target element */
function addClickOutsideListener(element, callback) {
  const listener = (event) => {
    if (!element.contains(event.target)) {
      callback(event);
    }
  };

  window.addEventListener('click', listener);

  return listener;
}

function removeClickOutsideListener(listener) {
  window.removeEventListener('click', listener);
}

module.exports = {
  listen: addClickOutsideListener,
  cleanup: removeClickOutsideListener,
};
