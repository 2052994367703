/* eslint-disable max-classes-per-file */

// Adaption of Ruby 'Enum' Hashes
const compareEnum = (a, b) => {
  if (a.sort !== undefined) {
    return a.sort - b.sort;
  }

  if (a.sortOrder !== undefined) {
    return a.sortOrder - b.sortOrder;
  }

  if (a.sort_order !== undefined) {
    return a.sort_order - b.sort_order;
  }

  return a.key.localeCompare(b.key);
};

export class EnumValue {
  constructor({ id, key, ...props }) {
    this.id = id;
    this.key = key;

    Object.entries(props).forEach(([propKey, value]) => {
      Object.defineProperty(this, propKey, { value });
    });
  }

  toString() {
    return this.key;
  }

  /*
  Accepts another instance, an id value, or a string key
   */
  equals(other) {
    if (other instanceof EnumValue) {
      return this.id === other.id;
    }

    const otherId = Number.parseInt(other);

    if (Number.isNaN(otherId)) {
      return this.key === other;
    }

    return this.id === otherId;
  }
}

export class Enum {
  constructor(values = [], ValueClass = EnumValue) {
    const byId = {};
    const bySymbol = {};

    values.forEach((rawValue) => {
      const value = new ValueClass(rawValue);
      byId[value.id] = value;
      bySymbol[value.key] = value;

      Object.defineProperty(this, value.key.toUpperCase(), { value });
    });

    this._byId = byId;
    this._bySymbol = bySymbol;
    this._valueClass = ValueClass;
  }

  get ids() {
    return Object.keys(this._byId);
  }

  get keys() {
    return Object.keys(this._bySymbol);
  }

  item(idOrSymbol) {
    if (idOrSymbol instanceof this._valueClass) {
      return idOrSymbol;
    }

    const id = parseInt(idOrSymbol);

    let item = null;

    if (!Number.isNaN(id)) {
      item = this._byId[id];
    } else if (typeof idOrSymbol === 'string') {
      item = this._bySymbol[idOrSymbol];
    }

    if (!item) {
      throw new Error('Unable to determine enum item');
    }

    return item;
  }

  items(idOrSymbols = this.ids) {
    return idOrSymbols
      .map(id => this.item(id))
      .filter(item => item !== undefined)
      .sort(compareEnum);
  }

  sortItems(items) {
    return items.sort(compareEnum);
  }
}
