import emailMatchesDomain from './email_matches_domain';

export default function isValidEmail(email, validDomainNames = undefined) {
  if (email) {
    // Use normal browser email sanity check (not validation) for consistent
    // behavior.
    const isValidFormat = Object.assign(document.createElement('input'), {
      required: true,
      type: 'email',
      value: email,
    }).validity.valid;

    if (validDomainNames) {
      return isValidFormat && emailMatchesDomain(email, ...validDomainNames);
    }

    return isValidFormat;
  }

  return false;
}
