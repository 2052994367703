import * as propTypes from 'lib/prop_types';

export const stripeCardIdPropType = propTypes.string;

export const stripeCardPropType = propTypes.shape({
  brand: propTypes.string,
  displayName: propTypes.string, // TODO 18338 Make this required and remove brand and last4
  id: stripeCardIdPropType,
  last4: propTypes.string,
});
