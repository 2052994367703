import { QueryString } from 'lib/http';

export default function oauthPath({
  action,
  provider,
  returnTo,
  userType,
} = {}) {
  const queryParams = {
    action,
    user_type: userType,
  };

  if (returnTo) {
    queryParams.return_to = returnTo;
  }

  return `/auth/${provider}?${QueryString.stringify(queryParams)}`;
}
