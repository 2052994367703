// NOTE: Logging to appsignal with this approach will not include the contextual
// namespace (eg: panel_value, etc). Use the hook instead: hooks/use_error_logger.
// This hook will pull the namespace from context and ensure that
// it is included in the log sent to appsignal.

import appsignal from 'lib/telemetry/appsignal';

export function logError(error) {
  if (appsignal && 'sendError' in appsignal) {
    appsignal.sendError(error);
  }
}
