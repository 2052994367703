import * as Constants from './constants';

// TODO RS-21864 Temporary until we have a proper way to grab the Question type from back end defs
interface Question {
  pick: typeof Constants.QUESTION_PICK_ONE |
    typeof Constants.QUESTION_PICK_ANY |
    typeof Constants.QUESTION_PICK_NONE,
}

export const isPickOneQuestion = (question: Question) => question.pick === Constants.QUESTION_PICK_ONE;

export enum QuestionEntityType {
  QUESTION = 'QUESTION',
  QUESTION_GROUP = 'QUESTION_GROUP',
}

interface QuestionBase {
  id: string,
  questionGroup?: {
    uuid: string,
  }
}

interface QuestionGroupEntity<T extends QuestionBase> {
  type: QuestionEntityType.QUESTION_GROUP,
  questionGroup: Required<T>['questionGroup'],
  questions: T[],
}

interface QuestionEntity<T extends QuestionBase> {
  type: QuestionEntityType.QUESTION,
  question: T,
}

export const groupQuestionsByQuestionGroup = <T extends QuestionBase>(questions: T[]) => {
  const NO_GROUP_ID = 'NO_GROUP_ID';
  const questionGroupsMap: Map<string, QuestionGroupEntity<T> | QuestionEntity<T>> = new Map();

  for (const question of questions) {
    const { questionGroup } = question;

    const isPartOfGroup = questionGroup !== null && typeof questionGroup !== 'undefined';

    if (isPartOfGroup) {
      const groupId = questionGroup.uuid;
      const questionGroupEntity = questionGroupsMap.get(groupId) as QuestionGroupEntity<T>;

      if (!questionGroupEntity) {
        questionGroupsMap.set(
          groupId,
          {
            type: QuestionEntityType.QUESTION_GROUP,
            questionGroup,
            questions: [question],
          },
        );
      } else {
        questionGroupEntity.questions?.push(question);
      }
    } else {
      questionGroupsMap.set(
        `${NO_GROUP_ID}${question.id}`,
        {
          type: QuestionEntityType.QUESTION,
          question,
        },
      );
    }
  }

  return Array.from(questionGroupsMap.values());
};
