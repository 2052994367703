import { isClient } from './ssr_utils';

/* eslint-disable no-bitwise */
const generateUUID = () => {
  // Retrieved from https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  let d = new Date().getTime();

  if (isClient && window.performance && typeof window.performance.now === 'function') {
    d += performance.now();
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + (Math.random() * 16)) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
};

export default generateUUID;
