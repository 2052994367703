import {
  buildAnswer,
  buildQuestion,
  removeFromArray,
  removeQuestionAndAnswers,
} from './utilities';

export const MAX_COLUMN_COUNT = 7;
export const MAX_ROW_COUNT = 20;
export const MIN_COLUMN_COUNT = 2;
export const MIN_ROW_COUNT = 2;

const buildQuestionRow = ({
  question,
  survey,
}) => {
  const answers = {};
  const questions = {};

  question.answerIds.forEach(answerId => {
    const answer = buildAnswer(question.pick);

    answers[answer.uuid] = {
      ...answer,
      text: survey.answers[answerId].text,
    };
  });

  const newQuestion = buildQuestion({
    answerIds: Object.keys(answers),
    pick: question.pick,
    questionGroupId: question.questionGroupId,
  });

  questions[newQuestion.uuid] = newQuestion;

  return {
    answers,
    questions,
  };
};

const addQuestionGroupColumn = (questionGroupUUID, survey) => {
  const questionGroup = survey.questions[questionGroupUUID];

  const firstQuestion = survey.questions[questionGroup.questionIds[0]];

  if (firstQuestion.answerIds.length >= MAX_COLUMN_COUNT) return {};

  const answers = { ...survey.answers };
  const questions = { ...survey.questions };

  questionGroup.questionIds.forEach(questionId => {
    const answer = buildAnswer(questionGroup.pick);
    let question = { ...questions[questionId] };
    question = {
      ...question,
      answerIds: [...question.answerIds, answer.uuid],
    };

    answers[answer.uuid] = answer;
    questions[question.uuid] = question;
  });

  return {
    answers,
    questions,
  };
};

const addQuestionGroupRow = (questionGroupUUID, survey) => {
  const questionGroup = survey.questions[questionGroupUUID];
  const question = survey.questions[questionGroup.questionIds[0]];

  if (questionGroup.questionIds.length >= MAX_ROW_COUNT) return {};

  const { questions, answers } = buildQuestionRow({ question, survey });

  const updatedQuestionGroup = {
    ...questionGroup,
    questionIds: [...questionGroup.questionIds, ...Object.keys(questions)],
  };

  return {
    answers: { ...survey.answers, ...answers },
    questions: { ...survey.questions, ...questions, [updatedQuestionGroup.uuid]: updatedQuestionGroup },
  };
};

const removeQuestionGroupColumn = ({
  questionGroupUUID,
  answerIndex,
  survey,
}) => {
  const questionGroup = survey.questions[questionGroupUUID];
  const firstQuestion = survey.questions[questionGroup.questionIds[0]];

  if (firstQuestion.answerIds.length <= MIN_COLUMN_COUNT) return {};

  const answers = { ...survey.answers };
  const questions = { ...survey.questions };

  questionGroup.questionIds.forEach(questionId => {
    const question = survey.questions[questionId];
    const answerIdToRemove = question.answerIds[answerIndex];

    const answerIds = removeFromArray(question.answerIds, answerIdToRemove);

    questions[questionId] = {
      ...question,
      answerIds,
    };

    delete answers[answerIdToRemove];
  });

  return {
    answers,
    questions,
  };
};

const removeQuestionGroupRow = ({
  questionGroupUUID,
  questionUUID,
  survey,
}) => {
  const questionGroup = survey.questions[questionGroupUUID];

  if (questionGroup.questionIds.length <= MIN_ROW_COUNT) return {};

  const question = survey.questions[questionUUID];

  const { answers, questions } = removeQuestionAndAnswers(question, survey);

  const questionIds = removeFromArray(questionGroup.questionIds, questionUUID);

  const updatedQuestionGroup = {
    ...questionGroup,
    questionIds,
  };

  return {
    answers,
    questions: { ...questions, [questionGroupUUID]: updatedQuestionGroup },
  };
};

const updateQuestionGroupColumn = ({
  questionGroupUUID,
  answerIndex,
  updates,
  survey,
}) => {
  const questionGroup = survey.questions[questionGroupUUID];

  const answers = { ...survey.answers };

  questionGroup.questionIds.forEach(questionId => {
    const question = survey.questions[questionId];
    const answer = survey.answers[question.answerIds[answerIndex]];

    answers[answer.uuid] = {
      ...answer,
      ...updates,
    };
  });

  return {
    answers,
  };
};

export const QuestionGroupUtilities = {
  addColumn: addQuestionGroupColumn,
  addRow: addQuestionGroupRow,
  removeColumn: removeQuestionGroupColumn,
  removeRow: removeQuestionGroupRow,
  updateColumn: updateQuestionGroupColumn,
};
