import ReactOnRails from 'react-on-rails';

export const registerComponent = (name, component) => {
  if (ReactOnRails.registeredComponents().has(name)) {
    // eslint-disable-next-line no-console
    console.warn(`Tried to register component with name ${name} which was already registered.`);
    return;
  }

  ReactOnRails.register({ [name]: component });
};
