import Http from 'lib/http';
import * as routes from 'lib/routes';

function addLabelsToParticipants(
  labels,
  participantPopulationId,
  participantFilter,
) {
  return Http.post(
    routes.labels_add_to_participants_path({
      participant_population_id: participantPopulationId,
    }),
    { labels, participant_filter: participantFilter },
  );
}

function getPopulationLabels(participantPopulationId) {
  if (!participantPopulationId) {
    return Promise.resolve([]);
  }

  return Http.get(
    routes.labels_get_for_population_path({
      participant_population_id: participantPopulationId,
    }),
  );
}

function removeLabelFromParticipant(participantPopulationLabelId, participantId) {
  return Http.delete(routes.labels_delete_from_participants_path(), {
    participant_ids: [participantId],
    participant_population_label_ids: [participantPopulationLabelId],
  });
}

const LabelService = {
  addLabelsToParticipants,
  getPopulationLabels,
  removeLabelFromParticipant,
};

export default LabelService;
