import { JsonApiDataStore } from 'jsonapi-datastore';

import http from 'lib/http';
import * as routes from 'lib/routes';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_NUMBER = 1;

export default class AccountService {
  static async search(search = '', pagination = {}, options = {}) {
    const { filter, include } = options;
    const { size, number } = pagination;

    // Yes -- this is a convoluted process to build this, but before refactoring
    // tread lightly. JSRoutes doesn't handle empty values in a normal way and
    // will leave empty values in the string in a weird way. Therefore wrote this
    // to only add values to the urlParams hash if there are values. -BHS
    const urlParams = {
      page: {
        size: size || DEFAULT_PAGE_SIZE,
        number: number || DEFAULT_PAGE_NUMBER,
      },
      sort: 'last_name,first_name',
    };

    if (Object.keys(filter || {}).length || search) {
      urlParams.filter = {
        ...(filter || {}),
      };

      if (search) {
        urlParams.filter.omniSearch = search;
      }
    }

    if (include) {
      urlParams.include = include;
    }

    const response = await http.get(routes.api_accounts_path(urlParams));
    return AccountService.parseResponseWithMeta(response);
  }

  static parseResponseWithMeta(response) {
    return new JsonApiDataStore().syncWithMeta(response);
  }
}
