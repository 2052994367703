import { z } from 'zod';
import { customContextSchema } from './custom_context_schema';

function generateEnv(): z.infer<typeof customContextSchema>['env'] | null {
  if (typeof window === 'undefined') return null;

  const scriptTag = document.getElementById('js-react-on-rails-context');

  if (!scriptTag) return null;

  const rawContent = JSON.parse(scriptTag.innerHTML);

  return customContextSchema.parse(rawContent)?.env;
}

export const ENV = generateEnv();
