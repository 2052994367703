// Keep this eslint-disable here. FontAwesome imports should only be happening
// in this file and nowhere else in the codebase.
//
// Any direct import from '@fortawesome/free-brands-svg-icons' will create bloat in
// our overall bundle size.

/* eslint-disable no-restricted-imports */

import { library, dom } from '@fortawesome/fontawesome-svg-core';

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';

library.add(faFacebook);
library.add(faGoogle);
library.add(faLinkedin);

export {
  faFacebook,
  faGoogle,
  faLinkedin,
  faLinkedinIn,
  faTwitter,
  faXTwitter,
};

// This will make it so we automatically check the DOM for updates which allows
// us to use CSS selectors to create icons outside of React.
dom.watch();
