import { isServer } from './ssr_utils';

export function getCSRFParam() {
  if (isServer) return null;

  return document.querySelector('meta[name="csrf-param"]').getAttribute('content');
}

export function getCSRFToken() {
  if (isServer) return null;

  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}
