/*
This regex will break up an http path by <module> and <id> groups and should
pass for both integer and hashid forms, tested on:
  /projects/32/staff/debug
  /project-participants/32/staff/1234
  /project_participants/32/staff/debug
  /project_participants/pp-f837s73s/projects/1234
  /no-way/module/jose
*/
export const pathRegex = /(\/(?<module>[a-z\-_]+))(\/(?<id>(\d+)|([a-z]{2,3}-[a-z0-9]{6,8})))*/gi;

const specialRegex = {
  JOIN: /\/join\/[\w-]{5,}(?<remainder>\/.+)?/gi,
  R: /\/r\/[\w-]{5,}(?<remainder>\/.+)?/gi,
  // eslint-disable-next-line max-len
  FACEBOOK: /\/social\/facebook\/sharing\/(?<accessPath>screener|digest)\/(?<accessCode>[\w-]{10})(?<remainder>\/.+)?/gi,
  OPT_IN: /\/opt-in\/(?<access_code>[\w-]{24,})(?<remainder>\/.+)?/gi,
  // eslint-disable-next-line max-len
  PREPAID_BALANCES: /\/prepaid-balances\/(?<prepaidBalanceId>\d+\/)?invitations\/(?<code>[\w-]{48,})(?<remainder>\/.+)?/gi,
  PRE_TASK: /\/pre-task\/(?<projectParticipantSecretKey>\w{32})(?<remainder>\/.+)?/gi,
  PROJECT_APPLY: /\/projects\/(?<accessCode>[\w-]{10})\/apply(?<remainder>\/.+)?/gi,
  PROJECT_COLLAB: /\/projects\/accounts\/invitations\/(?<code>[\w\-\\=]{8,})\/accept(?<remainder>\/.+)?/gi,
  SURVEYS: /\/surveys\/(?<surveyCode>[\w-]{10})(\/(?<responseSetCode>[\w-]{10})(?<remainder>\/.+)?)?/gi,
};

export function determineAction(uri: string) {
  return specialAction(uri) || defaultAction(uri);
}

function checkRegex(uri: string, regex: RegExp) {
  const result = uri.matchAll(regex).next();

  return result?.value;
}

function buildJoin(uri: string) {
  const result = checkRegex(uri, specialRegex.JOIN);

  if (!result) { return null; }

  const { remainder } = result.groups;

  // eslint-disable-next-line prefer-template
  return `/join/:referrer` + defaultAction(remainder);
}

function buildFacebook(uri: string) {
  const result = checkRegex(uri, specialRegex.FACEBOOK);

  if (!result) { return null; }

  const { accessPath, remainder } = result.groups;

  // eslint-disable-next-line prefer-template
  return `/social/facebook/sharing/${accessPath}/:access_code` + defaultAction(remainder);
}

function buildOptIn(uri: string) {
  const result = checkRegex(uri, specialRegex.OPT_IN);

  if (!result) { return null; }

  const { remainder } = result.groups;

  // eslint-disable-next-line prefer-template
  return '/opt-in/:access_code' + defaultAction(remainder);
}

function buildPreTask(uri: string) {
  const result = checkRegex(uri, specialRegex.PRE_TASK);

  if (!result) { return null; }

  const { remainder } = result.groups;

  // eslint-disable-next-line prefer-template
  return '/pre-task/:project_participant_secret_key' + defaultAction(remainder);
}

function buildProjects(uri: string) {
  let action; let result;

  // eslint-disable-next-line no-cond-assign
  if (result = checkRegex(uri, specialRegex.PROJECT_APPLY)) {
    action = '/projects/:access_code/apply';
    // eslint-disable-next-line no-cond-assign
  } else if (result = checkRegex(uri, specialRegex.PROJECT_COLLAB)) {
    action = '/projects/accounts/invitations/:code/accept';
  } else {
    return null;
  }

  const { remainder } = result.groups;

  return action + defaultAction(remainder);
}

function buildPrepaidBalances(uri: string) {
  const result = checkRegex(uri, specialRegex.PREPAID_BALANCES);

  if (!result) { return null; }

  const { prepaidBalanceId, remainder } = result.groups;
  const idTerm = prepaidBalanceId ? ':prepaid_balance_id/' : '';

  return `/prepaid-balances/${idTerm}invitations/:code${defaultAction(remainder)}`;
}

function buildR(uri: string) {
  const result = checkRegex(uri, specialRegex.R);

  if (!result) { return null; }

  const { remainder } = result.groups;

  // eslint-disable-next-line prefer-template
  return '/r/:general_referral_code' + defaultAction(remainder);
}

function buildSurveys(uri: string) {
  const result = checkRegex(uri, specialRegex.SURVEYS);

  if (!result) { return null; }

  const { responseSetCode, remainder } = result.groups;

  let action = '/surveys/:survey_code';

  if (responseSetCode) { action += '/:response_set_code'; }

  return action + defaultAction(remainder);
}

// access codes are 10 char urlsafe (/w\-){10}
function specialAction(uri: string) {
  return [
    buildJoin,
    buildFacebook,
    buildOptIn,
    buildPreTask,
    buildPrepaidBalances,
    buildProjects,
    buildR,
    buildSurveys,
  ].reduce<string | null>((value, actionBuilder) => value || actionBuilder(uri), null);
}

function defaultAction(uri: string) {
  let action = '';

  if (!uri) { return action; }

  for (const result of uri.matchAll(pathRegex)) {
    if (result.groups) {
      const { module, id } = result.groups;

      let idTerm = '';

      if (id) {
        const idParam = module.endsWith('s') ? module.slice(0, module.length - 1) : module;
        idTerm = `/:${idParam}_id`;
      }

      action += `/${module}${idTerm}`;
    }
  }

  return action || uri;
}
