import { isServer } from './ssr_utils';
/*
Trying to get bootstrap tabs to autoload based on link hash... the problem is that jquery might
not fully initialize by the time this hits, so need to figure that out more... or whether we can
use bootstrap tabs without jQuery at all, or perhaps listen for a more specific event
 */
export function onloadBootstrapNavTabCheck(tabListId) {
  if (isServer) return;

  const { hash } = window.location;

  if (!hash) {
    return;
  }

  document.addEventListener('DOMContentLoaded', () => {
    let target = $(`${tabListId} [data-target='${hash}']`);

    if (!target.length) {
      target = $(`${tabListId} [href='${hash}']`);
    }

    if (target.length) {
      target.tab('show');
    }
  });
}

export const breakpoints = {
  xs: 370,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,

  // NOTE: These are for tweaking layout edge cases, you should not be depending on these regularly
  iPhone6: 375,
  iPadVertical: 768,
  iPadHorizontal: 1024,
};

export function windowIsMobile(tablet = true) {
  if (isServer) return false;

  if (tablet) return window.innerWidth <= breakpoints.md;

  return window.innerWidth < breakpoints.sm;
}
