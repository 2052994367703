export default function emailMatchesDomain(email, ...domains) {
  if (email && domains) {
    // It should be noted that technically having an @ character is valid in the
    // "local part" of the email, therefore we should only grab the data after
    // the last @, which is what this split and slice combination does.
    return domains.includes(email.split('@').pop());
  }

  return false;
}
