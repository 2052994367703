import generateUUID from 'lib/generate_uuid';

import * as Constants from 'common/components/survey_builder/constants';
import {
  Answer,
  BackEndSurvey,
  FormType,
  Question,
  QuestionGroup,
  QuestionPick,
  QuestionType,
  Section,
  Survey,
} from './types';

const DEFAULT_QUESTION_COUNT = 3;
const DEFAULT_ANSWER_COUNT = 3;

// TODO: TDW-SUNSET
export const isPickOneOrAny = (question: Question | QuestionGroup) =>
  question.pick === Constants.QUESTION_PICK_ONE ||
  question.pick === Constants.QUESTION_PICK_ANY;

export const isBooleanType = (questionType: string) => questionType === Constants.QUESTION_TYPE_BOOLEAN;

export const isPickNoneType = (questionType: string) =>
  questionType === Constants.QUESTION_TYPE_STRING ||
  questionType === Constants.QUESTION_TYPE_TEXT ||
  questionType === Constants.QUESTION_TYPE_DATE ||
  questionType === Constants.QUESTION_TYPE_FLOAT;

export const isPickOneOrAnyType = (questionType: QuestionType) =>
  questionType === Constants.QUESTION_TYPE_PICK_ONE ||
  questionType === Constants.QUESTION_TYPE_PICK_ANY;

export const isPickOneOrAnyGridType = (questionType: QuestionType) =>
  questionType === Constants.QUESTION_TYPE_PICK_ONE_GRID ||
  questionType === Constants.QUESTION_TYPE_PICK_ANY_GRID;

export function isQuestionGroup(question: Question | QuestionGroup): question is QuestionGroup {
  return !!(question as QuestionGroup).questionIds;
}

const chooseResponseClassForBuildAnswer = (pick: QuestionPick) => {
  switch (pick) {
    case Constants.QUESTION_PICK_NONE:
      return Constants.RESPONSE_CLASS_STRING
    case Constants.QUESTION_BOOLEAN:
      return Constants.RESPONSE_CLASS_BOOLEAN
    default:
      return Constants.RESPONSE_CLASS_ANSWER
  }
}

export const buildAnswer = (pick: QuestionPick, attrs = {}): Answer => ({
  errors: {},
  qualifyLogic: Constants.QUALIFY_LOGIC_MAY,
  responseClass: chooseResponseClassForBuildAnswer(pick),
  text: '',
  uuid: generateUUID(),
  ...attrs,
});

export const buildOtherAnswer = (attrs = {}) => ({
  errors: {},
  qualifyLogic: Constants.QUALIFY_LOGIC_MAY,
  responseClass: Constants.RESPONSE_CLASS_OTHER_AND_STRING,
  text: 'Other',
  uuid: generateUUID(),
  ...attrs,
});

export const buildAnswersForQuestion = (
  question: Question | QuestionGroup,
  answerCount = DEFAULT_ANSWER_COUNT,
): Answer[] => {
  if (question.pick === 'none') {
    return [buildAnswer(question.pick)];
  }

  if (isPickOneOrAny(question)) {
    const answerArray = [];

    for (let step = 0; step < answerCount; step++) {
      answerArray.push(buildAnswer(question.pick));
    }

    return answerArray;
  }

  if (question.pick === Constants.QUESTION_BOOLEAN) {
    return [buildAnswer(question.pick, { text: 'Yes' }), buildAnswer(question.pick, { text: 'No' })]
  }

  return [];
};

export const buildQuestion = (attrs = {}): Question => ({
  answerIds: [],
  errors: {},
  isEditing: true,
  isMandatory: true,
  isKey: false,
  isNew: true,
  pick: Constants.QUESTION_PICK_ONE,
  text: '',
  uuid: generateUUID(),
  ...attrs,
});

export const buildQuestionsWithAnswers = (
  questionGroup: QuestionGroup,
  questionCount = DEFAULT_QUESTION_COUNT,
  answerCount = DEFAULT_ANSWER_COUNT,
) => {
  let answerArray: Answer[] = [];
  const questions: { [key: string]: Question } = {};

  for (let step = 0; step < questionCount; step++) {
    const questionAnswers = buildAnswersForQuestion(questionGroup, answerCount);

    answerArray = answerArray.concat(questionAnswers);

    const question = buildQuestion({
      answerIds: questionAnswers.map(answer => answer.uuid),
      pick: questionGroup.pick,
      questionGroupId: questionGroup.uuid,
    });

    questions[question.uuid] = question;
  }

  const answers = answerArray.reduce((acc, answer) => {
    acc[answer.uuid] = answer;
    return acc;
  }, {} as { [key: string]: Answer });

  return {
    answers,
    questions,
  };
};

export const removeQuestionAndAnswers = (question: Question | QuestionGroup, survey: Survey) => {
  const surveyCopy = { ...survey }
  let answers = { ...surveyCopy.answers };
  let questions = { ...surveyCopy.questions };

  // Remove original nested grouped questions and corresponding answers if grid question
  if (isQuestionGroup(question)) {
    question.questionIds.forEach(questionUUID => {
      ({ answers, questions } = removeQuestionAndAnswers(questions[questionUUID], { ...surveyCopy, answers, questions }));
    });
  } else {
    // Remove original answers if non grid question
    question.answerIds.forEach((answerId) => {
      delete answers[answerId];
    });
  }

  // Remove original question
  delete questions[question.uuid];

  return {
    answers,
    questions,
  };
};

export const buildQuestionGroup = (attrs = {}) => ({
  errors: {},
  isEditing: true,
  isMandatory: true,
  isKey: false,
  isNew: true,
  pick: Constants.QUESTION_PICK_ONE,
  questionIds: [],
  text: '',
  uuid: generateUUID(),
  ...attrs,
});

export const buildSection = (attrs = {}) => ({
  errors: {},
  questionIds: [],
  skipLogicIds: [],
  title: 'New Page',
  uuid: generateUUID(),
  ...attrs,
});

export const buildSkipLogic = () => ({
  conditionIds: [],
  errors: {},
  joinMethod: Constants.JOIN_METHOD_OR,
  targetSectionId: '',
  uuid: generateUUID(),
});

export const buildSkipLogicCondition = () => ({
  answerId: '',
  errors: {},
  operator: Constants.CONDITION_OPERATOR_EQL,
  questionId: '',
  uuid: generateUUID(),
});

// Moved without change from SurveyBuilder
// TODO: RS-20868 Remove mutations
export const cleanSectionTitles = (sectionIds: string[], sections: { [key: string]: Section }, formType: FormType) => {
  // Reset all the page titles to be the correct names based on their position
  sectionIds.forEach((sectionId, index) => {
    const pageIndex = formType === Constants.OPT_IN_FORM_TYPE ? index + 2 : index + 1;
    // Ignore assignment here as that's the purpose of this method
    // eslint-disable-next-line no-param-reassign
    sections[sectionId].title = `Page ${pageIndex}`;
  });
};

// Moved without change from SurveyBuilder
// TODO: RS-20868 Possible generic function, move into lib/collections.js?
export const insertIntoArray = (array: string[], newId: string, insertId?: string) => {
  if (insertId) {
    const insertIndex = array.indexOf(insertId) + 1;
    return [
      ...array.slice(0, insertIndex),
      newId,
      ...array.slice(insertIndex),
    ];
  }

  return [
    ...array,
    newId,
  ];
};

// Moved without change from SurveyBuilder
// TODO: RS-20868 Possible generic function, move into lib/collections.js?
export const removeFromArray = (array: string[], id: string) => {
  const removeIndex = array.indexOf(id);
  return [
    ...array.slice(0, removeIndex),
    ...array.slice(removeIndex + 1),
  ];
};

export const mapQuestionsToQuestionGroup = (survey: BackEndSurvey) => {
  const questions = { ...survey.questions };
  const sections = { ...survey.sections };

  // Roll through every section
  survey.sectionIds.forEach(sectionId => {
    const section = survey.sections[sectionId];
    // These will change, any question group question ids replaced with questionGroupId
    const sectionQuestionIds: string[] = [];

    section.questionIds.forEach(questionId => {
      const questionGroupId = survey.questions[questionId]?.questionGroupId;

      if (questionGroupId) {
        // Add questionGroup to questions
        questions[questionGroupId] = survey.questionGroups[questionGroupId];
        // Add questionGroup uuid to section questionIds if it isn't already there
        if (!sectionQuestionIds.includes(questionGroupId)) sectionQuestionIds.push(questionGroupId);
      } else if (survey.questions[questionId]) {
        // Do nothing if is traditional question except put into the array if it exists
        sectionQuestionIds.push(questionId);
      }
    });

    // Update the questionIds on section
    sections[section.uuid] = {
      ...section,
      questionIds: sectionQuestionIds,
    };
  });

  return {
    ...survey,
    questions,
    sections,
  };
}

export const mapQuestionGroupToQuestions = (survey: Survey): BackEndSurvey => {
  const sections: { [key: string]: Section } = {};
  const questions = { ...survey.questions };
  const questionGroups: { [key: string]: QuestionGroup } = {};

  survey.sectionIds.forEach(sectionId => {
    const section = survey.sections[sectionId];

    // Rebuild section questionIds
    let sectionQuestionIds: string[] = [];

    section.questionIds.forEach(questionId => {
      const question = survey.questions[questionId];

      // If question is a questionGroup
      if (isQuestionGroup(question)) {
        // Add the question ids to the section questionIds
        sectionQuestionIds = sectionQuestionIds.concat(question.questionIds);

        // Update questionGroup to latest question since that is kept up-to-date
        questionGroups[question.uuid] = question;

        // All questions should match questionGroup isKey, isMandatory and pick
        question.questionIds.forEach(questionGroupQuestionId => {
          const questionGroupQuestion = survey.questions[questionGroupQuestionId];

          questions[questionGroupQuestion.uuid] = {
            ...questionGroupQuestion,
            questionGroup: question, // is this still necessary?
            isKey: question.isKey,
            isMandatory: question.isMandatory,
            pick: question.pick,
          };
        });
      } else {
        // Put the questionId into section questionIds with no changes
        sectionQuestionIds.push(questionId);
      }
    });

    sections[section.uuid] = {
      ...section,
      questionIds: sectionQuestionIds,
    };
  });

  return {
    ...survey,
    questions,
    questionGroups,
    sections,
  };
};

const responseClassFromNoPickQuestionType = (questionType: QuestionType) => {
  switch (questionType) {
    case Constants.QUESTION_TYPE_DATE: return Constants.RESPONSE_CLASS_DATE;
    case Constants.QUESTION_TYPE_FLOAT: return Constants.RESPONSE_CLASS_FLOAT;
    case Constants.QUESTION_TYPE_TEXT: return Constants.RESPONSE_CLASS_TEXT;
    default: return Constants.RESPONSE_CLASS_STRING;
  }
}

export const updateNoPickQuestionType = (questionUUID: string, newQuestionType: QuestionType, survey: Survey) => {
  const question = { ...survey.questions[questionUUID] };

  const {
    answers,
    questions,
  } = removeQuestionAndAnswers(question, survey);

  // For questionGroup, remove questionIds and questionGroupId
  if (isQuestionGroup(question)) {
    delete question.questionGroupId;
    delete (question as Partial<QuestionGroup>).questionIds;
  }

  // Create the new answer
  const responseClass = responseClassFromNoPickQuestionType(newQuestionType);

  const newAnswer = buildAnswer(question.pick, { responseClass });

  // Set as question answerIds
  (question as Question).answerIds = [newAnswer.uuid];

  // Update the pick
  question.pick = Constants.QUESTION_PICK_NONE;

  return {
    answers: { ...answers, [newAnswer.uuid]: newAnswer },
    questions: { ...questions, [question.uuid]: question },
  };
};

export const updateNoPickToNoPickQuestionType = (questionUUID: string, newQuestionType: QuestionType, survey: Survey) => {
  const question = { ...survey.questions[questionUUID] } as Question;
  const answer = { ...survey.answers[question.answerIds[0]] };

  const responseClass = responseClassFromNoPickQuestionType(newQuestionType);

  return {
    answers: { ...survey.answers, [answer.uuid]: { ...answer, responseClass } },
    questions: survey.questions,
  };
};

const determinePickQuestionFromQuestionType = (newQuestionType: QuestionType): QuestionPick => {
  switch (newQuestionType) {
    case Constants.QUESTION_TYPE_PICK_ONE:
      return Constants.QUESTION_PICK_ONE
    case Constants.QUESTION_TYPE_BOOLEAN:
      return Constants.QUESTION_BOOLEAN
    default:
      return Constants.QUESTION_PICK_ANY
  }
}

export const updatePickQuestionType = (questionUUID: string, newQuestionType: QuestionType, survey: Survey) => {
  const question = { ...survey.questions[questionUUID] };

  question.pick = determinePickQuestionFromQuestionType(newQuestionType);

  const {
    answers,
    questions,
  } = removeQuestionAndAnswers(question, survey);

  // For questionGroup, remove questionIds and questionGroupId
  if (isQuestionGroup(question)) {
    delete question.questionGroupId;
    delete (question as Partial<QuestionGroup>).questionIds;
  }

  // Create new answers
  const newAnswers = buildAnswersForQuestion(question).reduce((acc, answer) => {
    acc[answer.uuid] = answer;
    return acc;
  }, {} as { [key: string]: Answer });

  // Add answerIds to question
  (question as Question).answerIds = Object.keys(newAnswers);

  return {
    answers: { ...answers, ...newAnswers },
    questions: { ...questions, [question.uuid]: question },
  };
};

export const updatePickToPickQuestionType = (questionId: string, newQuestionType: QuestionType, survey: Survey) => {
  const pick = determinePickQuestionFromQuestionType(newQuestionType);

  // Switch pick for question group
  const question = {
    ...survey.questions[questionId],
    pick,
  } as Question;

  const updatedAnswers: { [key: string]: Answer } = {};

  // If we're moving from PICK_ANY to PICK_ONE we need to update all the MUST choices
  if (newQuestionType === Constants.QUESTION_TYPE_PICK_ONE) {
    question.answerIds.forEach((answerId) => {
      if (survey.answers[answerId].qualifyLogic === Constants.QUALIFY_LOGIC_MUST) {
        updatedAnswers[answerId] = {
          ...survey.answers[answerId],
          qualifyLogic: Constants.QUALIFY_LOGIC_MAY,
        };
      }
    });
  }

  return {
    answers: { ...survey.answers, ...updatedAnswers },
    questions: { ...survey.questions, [question.uuid]: question },
  };
};

export const updateGridQuestionType = (questionUUID: string, newQuestionType: QuestionType, survey: Survey) => {
  const {
    answers,
    questions,
  } = removeQuestionAndAnswers(survey.questions[questionUUID], survey);

  const pick = newQuestionType === Constants.QUESTION_TYPE_PICK_ONE_GRID ?
    Constants.QUESTION_PICK_ONE :
    Constants.QUESTION_PICK_ANY;

  // Turn the question into a questionGroup
  const question = {
    ...survey.questions[questionUUID],
    pick,
  };

  // Remove answerIds
  delete (question as Partial<Question>).answerIds;

  // Create the questions with answers
  const { answers: newAnswers, questions: newQuestions } = buildQuestionsWithAnswers(question as QuestionGroup);

  // Add question ids to questionGroup
  (question as QuestionGroup).questionIds = Object.keys(newQuestions);

  return {
    answers: {
      ...answers,
      ...newAnswers,
    },
    questions: {
      ...questions,
      ...newQuestions,
      [question.uuid]: question,
    },
  };
};

export const updateGridToGridQuestionType = (questionUUID: string, newQuestionType: QuestionType, survey: Survey) => {
  const pick = newQuestionType === Constants.QUESTION_TYPE_PICK_ONE_GRID ?
    Constants.QUESTION_PICK_ONE :
    Constants.QUESTION_PICK_ANY;

  // Switch pick for question group
  const questionGroup = {
    ...survey.questions[questionUUID],
    pick,
  } as QuestionGroup;

  const updatedQuestions: { [key: string]: Question } = {};

  // Switch pick for all the questions
  questionGroup.questionIds.forEach(questionId => {
    updatedQuestions[questionId] = {
      ...survey.questions[questionId],
      pick,
    } as Question;
  });

  const updatedAnswers: { [key: string]: Answer } = {};

  // If we're moving from PICK_ANY to PICK_ONE we need to update all the MUST choices
  if (newQuestionType === Constants.QUESTION_TYPE_PICK_ONE_GRID) {
    Object.values(updatedQuestions).forEach(updatedQuestion => {
      updatedQuestion.answerIds.forEach(answerId => {
        if (survey.answers[answerId].qualifyLogic === Constants.QUALIFY_LOGIC_MUST) {
          updatedAnswers[answerId] = {
            ...survey.answers[answerId],
            qualifyLogic: Constants.QUALIFY_LOGIC_MAY,
          };
        }
      });
    });
  }

  return {
    answers: { ...survey.answers, ...updatedAnswers },
    questions: { ...survey.questions, ...updatedQuestions, [questionGroup.uuid]: questionGroup },
  };
};

export const buildNewSurvey = () => {
  const newQuestion = buildQuestion();

  const newSection = buildSection({
    title: 'Page 1',
    questionIds: [newQuestion.uuid],
  });

  const answers: { [key: string]: Answer} = {};

  buildAnswersForQuestion(newQuestion).forEach((answer) => {
    answers[answer.uuid] = answer;
    newQuestion.answerIds.push(answer.uuid);
  });

  return {
    answers,
    errors: {},
    questions: { [newQuestion.uuid]: newQuestion },
    sectionIds: [newSection.uuid],
    sections: { [newSection.uuid]: newSection },
    skipLogics: {},
    skipLogicConditions: {},
  };
};

export const NEW_LINE_REGEX = /[\n\r]/g;
