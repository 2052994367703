import { REWARD_CURRENCIES } from 'lib/generated_constants/incentives';

const COMMON_OPTIONS = {
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
};

export function formatAmount(amount, currency = 'USD', options = {}) {
  const locale = REWARD_CURRENCIES[currency]?.currency_locale || 'en-US';
  const formatOptions = {
    ...COMMON_OPTIONS,
    currency,
    ...options,
  };

  const formatter = new Intl.NumberFormat(locale, formatOptions);

  const formattedAmount = formatter.format(amount);

  // NumberFormat does not provide the correct currency symbol for the Romanian lei.
  // It DOES format correctly when currencyDisplay: 'narrowSymbol' is provided in
  // the format options, but that option breaks on iOS < 14.1. Hence this hardcoded fix
  if (currency === 'RON' && formattedAmount.includes('RON')) {
    return formattedAmount.replace('RON', 'lei');
  }

  return formattedAmount;
}
